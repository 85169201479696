.page-list .header__search {
    border: none;
    background-color: #eaedf4;
    padding: 13px 0 24px;
    position: relative;
    z-index: 1;
}

.page-list .header__search:before {
    position: absolute;
    content: '';
    height: 12px;
    left: 0;
    right: 0;
    top: -12px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0% , rgba(0,0,0,.25) 58% , rgba(0,0,0,1) 100% );
}

.page-list .header__search .search__advanced .column {
    padding-top: 0;
}

.header__search .search__advanced.has-button {
    grid-template-columns: calc(100% - 256px - 16px) 256px;
}

@media only screen and (max-width: 1023px) {
    .header__search .search__advanced.has-button {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
    }
}

@media only screen and (max-width: 1023px) {
    .header__search .search__advanced.has-button .grid-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }
}

.header__search .search__advanced .header__search__buttons {
    display: grid;
    gap: 16px;
    grid-template-columns: 100px 140px;
}

@media only screen and (max-width: 1023px) {
    .header__search .search__advanced .header__search__buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0;
    }

    .header__search .search__advanced .header__search__buttons label.label {
        display: none !important;
    }

    .header__search .search__advanced .header__search__buttons .search__radius {
        height: auto !important;
    }

    .header__search .search__advanced .header__search__buttons .search__radius > span {
        font-size: 16px;
    }
}

.header__search .search__advanced .header__search__buttons *.mobile {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .header__search .search__advanced .header__search__buttons .column {
        padding: 8px 0;
    }

    .header__search .search__advanced .header__search__buttons .column.column-button {
        order: 1;
        border: none !important;
    }

    .header__search .search__advanced .header__search__buttons .column.column-button .button {
        width: 100%;
        font-size: 16px;
    }

    .header__search .search__advanced .header__search__buttons .column:not(:last-child) {
        border-bottom: 1px solid #fff;
    }

    .header__search .search__advanced .header__search__buttons *.desktop {
        display: none;
    }
    .header__search .search__advanced .header__search__buttons *.mobile {
        display: flex;
    }
}

.header__search .search__advanced .header__search__buttons .search__radius {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__search .search__advanced.has-button .button {
    width: 140px;
    height: 48px;
    font-size: 14px;
    padding: 0;
}

@media only screen and (max-width: 1023px) {
    .header__search .search__advanced.has-button .button-wrap {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

@media only screen and (max-width: 779px) {
    .header__search .search__advanced.has-button .button-wrap {
        position: relative;
        width: 100%;
    }

    .header__search .search__advanced.has-button .button-wrap .label {
        display: none;
    }

    .header__search .search__advanced.has-button .button-wrap .button {
        width: 100%;
    }

    .header__search .search__advanced.has-button .consultations {
        width: 100%;
    }

    .header__search .search__advanced.has-button .consultations .column {
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}

.page-list .header-content {
    padding: 20px 0 24px;
    border-top: 1px solid #ffffff;
    background-color: #eaedf4;
}

.page-list .header-content .justify {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .page-list .header-content .justify {
        flex-wrap: wrap;
        gap: 10px;
    }
}

.page-list .header-content h4 {
    font-size: 40px
}

@media only screen and (max-width: 1023px){

    .page-list .header-content h4 {
        font-size: 30px
    }
}

.page-list .header-content .header-content__list {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    color: #616161;
    font-size: 16px;
    line-height: 150%;
    font-weight: 350
}

.page-list .header-content .search__radius {
    display: flex;
    gap: 3px;
}