.crop-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.crop-modal {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    max-width: 600px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.crop-container {
    position: relative;
    width: 100%;
    height: 400px;
    background: #333;
}

.crop-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.zoom-controls {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.zoom-button {
    background: #f0f0f0;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 8px;
    transition: background 0.2s ease;
}

.zoom-button:hover {
    background: #e0e0e0;
}

.zoom-button svg {
    stroke: #333;
}

.zoom-percentage {
    font-size: 14px;
    color: #333;
}

.action-buttons {
    display: flex;
    gap: 8px;
}

.button {
    padding: 8px 12px;
    background: #ddd;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s ease, color 0.2s ease;
}

.button-black {
    background: #333;
    color: #fff;
}

.button:hover {
    background: #ccc;
}

.button-black:hover {
    background: #000;
}
.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Ensure it's above the crop elements */
}
.loader {
    color: red;
    background: red;
}