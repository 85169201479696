.zip {
    display: inline-flex;
    align-items: center;
    gap: 7px;
    vertical-align: bottom;
}

.zip span {
    font-size: 18px;
    line-height: 22px;
}

.tooltip {
    display: inline-block;
    position: relative;
}

.tooltip:hover .tooltip__content {
    opacity: 1;
    pointer-events: auto;
}

.tooltip__content {
    position: absolute;
    padding: 5px 5px 30px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;
    max-width: 500px;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    text-align: center;
}

.tooltip__text {
    box-shadow: 4px 4px 15px rgba(53, 73, 90, 0.2);
    padding: 6px 12px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.2;
    width: 100%;
}