.pagination {
    padding: 15px 0;
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: 100px calc(100% - 240px) 100px;
}
.pagination__el.active button {
    background: #EAEEF4;
}

.pagination__button {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    background: transparent;
    color: #1D1D1D;
    font-size: 1.6rem;
    transition: all 0.2s ease-in-out;
}

.pagination__button:hover {
    opacity: 0.8;
}

.pagination__button.disabled {
    opacity: 0.5 !important;
}

.pagination__button:last-child {
    justify-content: flex-end;
}

.pagination__button .icon {
    width: 24px;
    height: 24px;
    position: relative;
}

.pagination__button .icon:before {
    position: absolute;
    content: '';
    border: none;
    border-top: 2px solid #1D1D1D;
    border-right: 2px solid #1D1D1D;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
}

.pagination__button .icon.icon-angle-left:before {
    transform: translate(-50%, -50%) rotate(-135deg);
}

.pagination__button .icon.icon-angle-right:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.pagination__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.pagination__el span,
.pagination__el button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 0;
    background: transparent;
    color: #1D1D1D;
    font-size: 1.6rem;
    transition: background-color 0.3s;
    border-radius: 4px;
}

.pagination__el span {
    cursor: default;
    background: #EAEEF4;
}

.pagination__el button {
    cursor: pointer;
}

.pagination__el button:hover {
    background: #EAEEF4;
}

/* Стили для плавной анимации слайда */
.slide-enter, .slide-exit {
    position: relative;
}

.slide-enter::before, .slide-exit::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;
    opacity: 1;
    transition: opacity 1.2s ease-in-out; /* Увеличена продолжительность анимации */
}

.slide-enter-active::before, .slide-exit-active::before {
    opacity: 0;
}

.slide-enter {
    opacity: 0;
    transform: translateX(100%);
}

.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.slide-exit {
    opacity: 1;
    transform: translateX(0%);
}

.slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}


.tabs__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Обеспечивает, что пагинация всегда будет внизу */
    height: 100%; /* Позволяет контейнеру занимать всю высоту */
}

/* Стили для эффекта перелистывания */
.flip-enter {
    opacity: 0;
    background-color: white; /* Фон при входе */
}

.flip-enter-active {
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
}

.flip-exit {
    opacity: 1;
    background-color: white; /* Фон при выходе */
}

.flip-exit-active {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
}


.tabs__content {
    flex-grow: 1; /* Растягивает этот блок, чтобы заполнить доступное пространство */
    display: flex;
    flex-direction: column;
}

.pagination-container {
    margin-top: auto; /* Двигает пагинацию вниз */
}

.pagination__button.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}
