@font-face {
    font-family: Cygre;
    src: local("Cygre"), local("NTSomic-Regular"), url(../../public/assets/Cygre-Regular-Df412xv2.woff2) format("woff2")
}

@font-face {
    font-family: Cygre;
    font-weight: 350;
    src: local("Cygre-Book"), local("NTSomic-Medium"), url(../../public/assets/Cygre-Book-BGmDqmzS.woff2) format("woff2")
}

@font-face {
    font-family: Cygre;
    font-weight: 500;
    src: local("Cygre-Medium"), local("NTSomic-Medium"), url(../../public/assets/Cygre-Medium-BygqfH-M.woff2) format("woff2")
}

@font-face {
    font-family: Cygre;
    font-weight: 600;
    src: local("Cygre-SemiBold"), local("NTSomic-Semobold"), url(../../public/assets/Cygre-SemiBold-CsWoUdk_.woff2) format("woff2")
}

/* 1 */
label.label-checkbox input:checked + .psevdocheckbox {
    background: #2c4157 url(../../public/assets/label-checkbox.svg) no-repeat 50% 50%
}

/* 2 */
.main-form__img {
    background: url(../../public/assets/bg--main-form-9kzortC2.jpg) no-repeat 50% 50%;
    background-size: cover
}

/* 3 */
.header__banner {
    background: #2d3e51 url(../../public/assets/bg--header-banner-CvYgKZnF.png) no-repeat 50% 0;
    background-size: cover !important;
}

/* 4 */
.header__search .search__input label.label .input.fill, .header__search .search__input label.label .input:focus {
    background: #fff url(../../public/assets/icon--zoom-DblF_61P.svg) no-repeat 32px 50%
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .input.fill, .header__search .search__input label.label .input:focus {
        background: #fff url(../../public/assets/icon--zoom-DblF_61P.svg) no-repeat 12px 50%;
        background-size: 18px 18px
    }
}

/* 5 */
.header__search .search__advanced .column .multiselect-dropdown {
    background: #fff url(../../public/assets/icon--zoom-DblF_61P.svg) no-repeat 16px 50%;
    background-size: 20px 20px;
}

/* 6 */
.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper > input {
    background: #fff url(../../public/assets/icon--zoom-DblF_61P.svg) no-repeat 16px 50%;
    background-size: 20px 20px;
}

/* 7 */
.profile-pro .profile__headline.profile__headline-company, .profile-pro .profile__headline.profile__headline-attorney, .profile-pro .profile__headline.profile__headline-vendor {
    background: #2c4157 url(../../public/assets/bg--default-DLHu_gUV.jpg) no-repeat 50% 50%
}

/* 8 */
.profile-rough {
    background: #2c4157 url(../../public/assets/icon--profile-octagon-CWySRE0m.svg) no-repeat 98% 100%;
}

/* 9 */
.create-links .create-link {
    background: #f0f5fc url(../../public/assets/icon--profile-octagon-CWySRE0m.svg) no-repeat 95% 100%
}

/* 10 */
.vendor-day {
    background: #2c4157 url(../../public/assets/bg--vendor-day-ZSBgJbfO.jpg) no-repeat 50% 50%;
    background-size: cover !important;
    border-top: 1px solid #fff;
}

/* 11 */
.p-multiselect-label-container .p-multiselect-label .p-multiselect-token:first-child:before {
    background: url(../../public/assets/icon--zoom.svg) no-repeat 50%
}