.attorney {
    cursor: pointer;
}
.popup {
    position: fixed;
    top: 100px; /* Оставляем место для меню и баннера */
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

}

.popup.visible {
    opacity: 1;
    pointer-events: auto;
}

.popup .content {
    background: #fff;
    padding: 0;
    border-radius: 0;
    max-width: 100%; /* Увеличена максимальная ширина */
    max-height: 100%; /* Уменьшена максимальная высота */
    overflow-y: auto;
    position: relative;
    width: 100%;
}

.popup .close {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: 10;
    right: 18px;
    top: 18px;
    cursor: pointer;
}

.popup .close img {
    width: 24px;
    height: 24px;
}

.hours {
    list-style: none;
    padding: 0;
    margin: 0;
}

.hours li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.working-hours__day {
    flex: 1;
}

.working-hours__time {
    text-align: right;
    white-space: nowrap;
}
