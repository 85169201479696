.footer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 4rem 0;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__social {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__social h2 {
    text-align: center;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__social .social {
    display: flex;
    justify-content: center;
    gap: 16px;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__social .social li a img {
    width: 40px;
}

.footer .footer__separator {
    display: block;
    width: 1px;
    height: 80px;
    margin: 0 auto;
    background: #EAEEF4;
}

.footer .footer__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__logo {
    margin: 0 auto;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__copyright {
    display: block;
    text-align: center;
    color: #A7A4A4;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 500;
    vertical-align: -webkit-baseline-middle;
}

.footer .footer__nav ul {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    vertical-align: -webkit-baseline-middle;
}

@media only screen and (max-width: 779px) {
    .footer .footer__nav ul {
        justify-content: center;
    }
}

.footer .footer__nav ul li a {
    font-size: 1.6rem;
    line-height: 1.2rem;
    font-weight: 500;
    vertical-align: -webkit-baseline-middle;
}

@media only screen and (max-width: 779px) {
    .footer .footer__nav ul li {
        width: 45%;
        text-align: center;
    }

    .footer .footer__nav ul li:last-child {
        width: 100%;
    }
}
