main.emptyContent {
    background-color: #eaedf4;
    flex: 1 1 0;
    display: flex;
    align-items: end;
    justify-content: center;
    /*padding: 32px;*/
}

main.emptyContent .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    text-align: center;
    color: #1D1D1D;
}

main.emptyContent .content .icons {
    width:  56px;
    height:  56px;
}

main.emptyContent .content .title {
    display: block;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

main.emptyContent .content .description {
    max-width: 480px;
    font-weight: 350;
    font-size: 16px;
    /*line-height: 120%;*/
}

.centered-left-span {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 400px;
    text-align: left; /* Выравнивание текста по левому краю */
    padding: 10px 0; /* Отступы сверху и снизу */
    word-break: break-all; /* Жесткий разрыв слов при необходимости */
    overflow-wrap: anywhere; /* Позволяет переносу текста в любом месте, где это необходимо */
    white-space: normal; /* Стандартный перенос строк */
    line-height: 1.6; /* Улучшение читаемости текста с увеличенным межстрочным интервалом */
    hyphens: none; /* Отключаем автоматические переносы слов */
}









