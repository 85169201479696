/* style2.module.css */

/* Локализованные стили для компонента с классом .custom-multiselect */
.customMultiselect .p-multiselect-token {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 120%;
    color: #1D1D1D;
    font-weight: 500;
    background: #F2F7FD;
    margin: 2px;
    border-radius: 12px;
}

.customMultiselect .p-multiselect-token-placeholder {
    background-color: #dcdcdc; /* Настройте по вашему желанию */
}

.customMultiselect .p-multiselect-token-label {
    margin-right: 4px;
}

.customMultiselect .customValueContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.customMultiselect .p-multiselect {
    min-height: 48px;
    padding: 5px;
    background: #fff;
    overflow: visible; /* Позволяет отображать все содержимое */
}

/* Продолжайте аналогичным образом для всех других селекторов */

.customMultiselect .p-multiselect-panel {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin-top: 2px;
}

.customMultiselect .p-multiselect-header {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    align-items: center;
    align-content: center;
    padding: 10px;
    min-height: 44px;
}

.customMultiselect .p-multiselect-filter-container {
    font-size: 12px;
    line-height: 120%;
    height: 18px;
}

.customMultiselect .p-multiselect-items-wrapper {
    max-height: 40vh !important;
    scrollbar-width: thin;
    scrollbar-color: #B3C4DB #EAEEF4;
}

@media only screen and (max-width: 779px) {
    .customMultiselect .p-multiselect-items-wrapper {
        height: 40vh !important;
    }
}

.customMultiselect .p-multiselect-items.p-component {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.customMultiselect .p-multiselect-select-all {
    position: relative;
}

.customMultiselect .p-multiselect-empty-message,
.customMultiselect .p-multiselect-item {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    align-items: center;
    align-content: center;
    position: relative;
    padding: 10px;
    min-height: 44px;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    justify-content: flex-end;
}

.customMultiselect .p-multiselect-item .p-multiselect-checkbox {
    display: inline-block;
    position: relative;
}

.customMultiselect .p-multiselect-item > span {
    font-size: 16px;
    line-height: 1.2;
    width: calc(100% - 20px);
}

.customMultiselect .p-multiselect-select-all input.p-checkbox-input,
.customMultiselect .p-multiselect-checkbox input.p-checkbox-input {
    opacity: 0;
}

.customMultiselect .p-multiselect-select-all .p-checkbox-box,
.customMultiselect .p-multiselect-item .p-checkbox-box {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    align-content: center;
    pointer-events: none;
}

.customMultiselect .p-multiselect-select-all .p-checkbox-box {
    border: 1px solid #616161;
}

.customMultiselect .p-multiselect-token-icon {
    width: 12px;
    height: 12px;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M9.21953%201.62981L5.84899%205.00035L9.21963%208.37098C8.96906%208.68423%208.68435%208.96896%208.37111%209.21953L5.00046%205.84888L1.62977%209.21957C1.31654%208.969%201.03181%208.68427%200.78125%208.37104L4.15193%205.00035L0.781346%201.62976C1.03192%201.31653%201.31665%201.03181%201.62989%200.78125L5.00046%204.15182L8.371%200.781291C8.68423%201.03186%208.96896%201.31658%209.21953%201.62981Z%22%20fill%3D%22%23A4A9B0%22/%3E%3C/svg%3E');
    background-size: cover;
    display: inline-block;
    background-position: bottom center;
    fill: none;
}

.customMultiselect .p-multiselect-token-icon path {
    display: none; /* Убираем старую иконку */
}

/* Добавляем стили для .customParamsList */
.customParamsList {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 10px; /* Добавляем отступ сверху */
}

.customParamsList li {
    background: #fff;
    padding: 3px 20px;
}

/* Дополнительные стили для разных контекстов */
.mainFormContentAttorneysListAttorneyCustomParamsList {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 10px; /* Добавляем отступ сверху */
}

.mainFormContentAttorneysListAttorneyCustomParamsList li {
    background: #fff;
    padding: 3px 20px;
}

.modalFormAttorneysListAttorneyCustomParamsList {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 10px; /* Добавляем отступ сверху */
}

.modalFormAttorneysListAttorneyCustomParamsList li {
    background: #fff;
    padding: 3px 20px;
}

.profileCustomParamsList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px; /* Добавляем отступ сверху */
}

.profileCustomParamsList li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 142%;
    color: #1d1d1d;
    padding: 10px 20px;
    background: #f2f7fd;
}
