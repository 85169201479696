/* Add these styles to your CSS file */

.popup .no-pointer {
    cursor: default;
}

.popup .no-pointer .vendor__header {
    cursor: default;
}

.popup .vendor__link {
    cursor: pointer;
}

.popup .favorites {
    cursor: pointer;
}

/* Specific styles for current workplaces inside PopupAttorney */
.popup #attorney-type1 .vendor-verified .vendor__header {
    cursor: default;
}

.popup #attorney-type1 .vendor-verified .vendor__data {
    cursor: default;
}

/* Ensuring other vendor data is still clickable */
.popup #attorney-type1 .vendor__data:not(.vendor-verified) {
    cursor: pointer;
}

/* Ensuring contacts are still clickable */
.popup #attorney-type1 .vendor__contacts .vendor__link {
    cursor: pointer;
}
