/* GetPro.module.css */
.profileGetPro {
    background: #2c4157;
    color: #eaeef4;
    padding: 20px 0;
}

.profileGetPro * {
    color: #eaeef4;
}

.getproInfo {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    font-size: 16px;
    line-height: 150%;
}

.getproTable {
    color: #eaeef4;
    font-size: 18px;
    line-height: 142%;
}

.vendorTable {
    width: 100%;
}

.vendorTable tr:first-child td {
    border: none;
}

.vendorTable tr:last-child td {
    border: none;
    padding-bottom: 0;
}

.vendorTable tr td {
    padding: 16px 0;
    border-bottom: 1px solid #b3c4db;
}

.vendorTable tr td:nth-child(1) {
    width: 50%;
}

.vendorTable tr td:nth-child(2),
.vendorTable tr td:nth-child(3) {
    width: 25%;
}
/* GetPro.module.css */

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px;
    color: white;
    cursor: pointer;
    opacity: 0.8;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 20px;
}

.playButton:hover {
    opacity: 1;
}

.tabs__content {
    position: relative;
}

/* Стили для лайтбокса */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightboxContent {
    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 0;
    background: #000;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.closeButton:hover {
    background: #ddd;
}
/* GetPro.module.css */

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px; /* Фиксированная ширина */
    height: 80px; /* Фиксированная высота */
    display: flex; /* Используем Flexbox для центрирования содержимого */
    align-items: center; /* Центрируем по вертикали */
    justify-content: center; /* Центрируем по горизонтали */
    font-size: 40px; /* Размер иконки воспроизведения */
    color: white;
    cursor: pointer;
    opacity: 0.8;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%; /* Делаем кнопку круглой */
}

.playButton:hover {
    opacity: 1;
}

/* Убедитесь, что родительский контейнер имеет position: relative */
.tabs__content {
    position: relative;
}
/* Стили контейнера для кнопок */
.buttonContainer {
    display: flex;
    align-items: center;
    gap: 16px; /* Расстояние между кнопками, можете изменить по необходимости */
    margin-top: 20px; /* Отступ сверху, если нужно */
}
/* Стили для кнопки воспроизведения поверх видео */
.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px; /* Фиксированная ширина */
    height: 80px; /* Фиксированная высота */
    display: flex; /* Используем Flexbox для центрирования содержимого */
    align-items: center; /* Центрируем по вертикали */
    justify-content: center; /* Центрируем по горизонтали */
    font-size: 40px; /* Размер иконки воспроизведения */
    color: white;
    cursor: pointer;
    opacity: 0.8;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%; /* Делаем кнопку круглой */
}

.playButton:hover {
    opacity: 1;
}

/* Убедитесь, что родительский контейнер имеет position: relative */
.tabs__content {
    position: relative;
}
.playButtonIcon {
    width: 24px;
    height: 24px;
}
.icon {
  margin-right: 8px; /* Отступ между иконкой и текстом */
  /* Дополнительные стили для иконки, если необходимо */
}