/* src/components/ActivateTokenAccount.css */

.attorney-card {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.attorney-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.attorney-card img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 1px solid #2c4157 /* Зелёная рамка */
}

.attorney-info {
    flex: 1;
}

.attorney-info h3 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
    color: #333;
}

.attorney-info p {
    margin: 5px 0;
    color: #666;
    font-size: 1em;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .attorney-card {
        flex-direction: column;
        text-align: center;
    }

    .attorney-card img {
        margin: 0 0 15px 0;
    }

    .attorney-info h3 {
        font-size: 1.3em;
    }

    .attorney-info p {
        font-size: 0.95em;
    }
}
