.media-gallery-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Задаем одинаковую высоту для всех медиа-элементов */
    overflow: hidden;
    position: relative;
}

.media-gallery-image, .media-gallery-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.media-gallery-video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.play-icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px; /* Размер иконки */
    height: 60px; /* Размер иконки */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.play-icon {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.media-gallery-video-container:hover .play-icon {
    transform: scale(1.2); /* Увеличение иконки при наведении */
}

.modal-content {
    position: relative;
    background-color: transparent; /* Убираем белый фон */
    padding: 0; /* Убираем отступы */
    max-width: 90%;
    margin: 40px auto;
    text-align: center;
    z-index: 1000; /* Обеспечиваем, что модальное окно отображается поверх всего */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content .modal-image, .modal-content .modal-video {
    max-width: 95vw;
    max-height: 95vh; /* Ограничение по высоте до 90% от высоты экрана */
    object-fit: contain; /* Обеспечивает, что изображение или видео вписывается в контейнер */
}

.modal-content .modal-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: none;
    outline: none;
    background: black;
    max-height: 90vh;
    max-width: 90vw;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Обеспечиваем, что overlay отображается поверх всего */
}

.modal__button-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35.2px; /* 44px * 0.8 */
    height: 35.2px; /* 44px * 0.8 */
    transition: all .2s linear;
    background: transparent;
    z-index: 10;
    border: none;
    outline: none;
}

.modal__button-close img {
    width: 35.2px; /* 44px * 0.8 */
    height: 35.2px; /* 44px * 0.8 */
}

.modal__button-close:hover {
    opacity: .7;
}

.modal__button-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    padding: 0 20px;
}

.modal__button-prev {
    left: 0;
}

.modal__button-next {
    right: 0;
}

.modal__button-nav:hover {
    opacity: .7;
}
