:root {
    scroll-padding: 0
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-family: Cygre, Helvetica, Arial, sans-serif;
    vertical-align: baseline;
    max-width: 100%
}

html {
    font-size: 10px;
    line-height: 160%;
    color: #616161;
    scroll-behavior: smooth
}

body {
    font-size: 10px;
    line-height: 160%;
    color: #616161;
    background: #eaeef4
}

body.fixed-height {
    height: 100vh;
    overflow: hidden
}

body * {
    font: inherit;
    color: inherit
}

main {
    position: relative;
    z-index: 10
}

main.no-scroll {
    overflow: hidden; /* Отключаем прокрутку */
    position: fixed; /* Фиксируем элемент на месте */
    width: 100%; /* Устанавливаем ширину элемента */
    height: 100%; /* Устанавливаем высоту элемента */
}

a {
    text-decoration: none
}

a:active, a:hover {
    outline: 0
}

ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0
}

html {
    box-sizing: border-box
}

*, *:before, *:after {
    box-sizing: border-box
}

*:focus {
    outline: 0
}

.page {
    min-height: 100vh;
    width: 100%
}

.page.index {
    display: flex;
    flex-direction: column;
}

main {
    transition: all .3s ease-in-out
}

img, audio, video {
    max-width: 100%;
    height: auto
}

audio, canvas, iframe, video, img, svg {
    vertical-align: middle
}

iframe {
    border: 0
}

textarea {
    resize: none;
    overflow: auto;
    vertical-align: top;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none
}

input, textarea, select, button {
    outline: none;
    border: none;
    font-size: 100%;
    margin: 0
}

button, input {
    line-height: normal
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td, th {
    padding: 0;
    text-align: left
}

.text-center {
    text-align: center
}

.hidden {
    display: none !important
}

.loader {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0)
}

@keyframes mulShdSpin {
    0%, to {
        box-shadow: 0 -2.6em #fff, 1.8em -1.8em #fff3, 2.5em 0 #fff3, 1.75em 1.75em #fff3, 0 2.5em #fff3, -1.8em 1.8em #fff3, -2.6em 0 #ffffff80, -1.8em -1.8em #ffffffb3
    }
    12.5% {
        box-shadow: 0 -2.6em #ffffffb3, 1.8em -1.8em #fff, 2.5em 0 #fff3, 1.75em 1.75em #fff3, 0 2.5em #fff3, -1.8em 1.8em #fff3, -2.6em 0 #fff3, -1.8em -1.8em #ffffff80
    }
    25% {
        box-shadow: 0 -2.6em #ffffff80, 1.8em -1.8em #ffffffb3, 2.5em 0 #fff, 1.75em 1.75em #fff3, 0 2.5em #fff3, -1.8em 1.8em #fff3, -2.6em 0 #fff3, -1.8em -1.8em #fff3
    }
    37.5% {
        box-shadow: 0 -2.6em #fff3, 1.8em -1.8em #ffffff80, 2.5em 0 #ffffffb3, 1.75em 1.75em #fff, 0 2.5em #fff3, -1.8em 1.8em #fff3, -2.6em 0 #fff3, -1.8em -1.8em #fff3
    }
    50% {
        box-shadow: 0 -2.6em #fff3, 1.8em -1.8em #fff3, 2.5em 0 #ffffff80, 1.75em 1.75em #ffffffb3, 0 2.5em #fff, -1.8em 1.8em #fff3, -2.6em 0 #fff3, -1.8em -1.8em #fff3
    }
    62.5% {
        box-shadow: 0 -2.6em #fff3, 1.8em -1.8em #fff3, 2.5em 0 #fff3, 1.75em 1.75em #ffffff80, 0 2.5em #ffffffb3, -1.8em 1.8em #fff, -2.6em 0 #fff3, -1.8em -1.8em #fff3
    }
    75% {
        box-shadow: 0 -2.6em #fff3, 1.8em -1.8em #fff3, 2.5em 0 #fff3, 1.75em 1.75em #fff3, 0 2.5em #ffffff80, -1.8em 1.8em #ffffffb3, -2.6em 0 #fff, -1.8em -1.8em #fff3
    }
    87.5% {
        box-shadow: 0 -2.6em #fff3, 1.8em -1.8em #fff3, 2.5em 0 #fff3, 1.75em 1.75em #fff3, 0 2.5em #fff3, -1.8em 1.8em #ffffff80, -2.6em 0 #ffffffb3, -1.8em -1.8em #fff
    }
}

.tabs__caption {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    font-size: 18px;
    line-height: 142%;
    font-weight: 600;
    color: #1d1d1d
}

.tabs__caption li {
    cursor: pointer
}

.tabs__caption li.active {
    text-decoration: underline;
    cursor: default
}

.tabs__content {
    display: none
}

/*.tabs__content-wrapper.with-pagination {*/
/*    !*height: 550px;*!*/
/*    display: flex;*/
/*    justify-content: space-between; !* Выравнивание содержимого и пагинации *!*/
/*}*/

.tabs__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Обеспечивает, что пагинация всегда будет внизу */
    height: 100%; /* Позволяет контейнеру занимать всю высоту */
    background-color: white; /* Задний фон белого цвета для маскировки */
    overflow: hidden; /* Скрываем все, что выходит за пределы контейнера */

}

.tabs__content.active {
    display: block;
    transition: transform 0.6s ease-in-out; /* Плавное перелистывание */

}

.inner-content {
    background: #fff
}

.container {
    width: 100%;
    padding: 0 4rem;
    max-width: 144rem;
    margin: 0 auto
}

@media only screen and (max-width: 779px) {
    .container {
        padding: 0 15px
    }
}

.col-2 {
    display: grid;
    column-gap: 64px;
    grid-template-columns:repeat(2, calc(50% - 32px))
}

@media only screen and (max-width: 1023px) {
    .col-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px
    }
}

.input {
    display: block;
    width: 100%;
    height: 55px;
    /*border: 1px solid #ccc; !* Добавляем базовую границу *!*/
    outline: none;
    padding-left: 16px;
    background: #fff;
    font-size: 16px;
    line-height: 150%;
    transition: border-color 0.3s, background-color 0.3s;
}

.input-error {
    background-color: #F5D4D4; /* Красный фон при ошибке */
    /*border: 1px solid #e74c3c; !* Красная рамка при ошибке *!*/
}

/* Дополнительные стили при фокусе на поле с ошибкой */
.input-error:focus {
    border-color: #c0392b;
}

/* Изменение цвета placeholder при ошибке, если необходимо */
.input-error + .placeholder {
    color: #e74c3c;
}

/* При наличии класса input-error, изменяем стили */
.input.input-error:focus {
    border-color: #c0392b; /* Красная рамка при фокусе на ошибочном поле */
}


.input::placeholder {
    color: #a7a4a4;
    transition: all .2s ease-in-out
}

.input:focus::placeholder {
    opacity: 0
}

textarea.input {
    height: 120px;
    resize: none
}

label.input-label {
    position: relative;
    display: block;
    width: 100%
}

label.input-label.input-attorney {
    display: flex;
    gap: 8px;
    align-items: flex-start
}

label.input-label.input-attorney .input {
    width: calc(100% - 65px)
}

label.input-label.input-attorney .button {
    height: 55px;
    width: 120px;
    padding-left: 0;
    padding-right: 0
}

label.input-label.button-inside {
    width: 100%;
}

label.input-label.button-inside .input {
    padding-top: 0;
    padding-right: 130px;
    color: #A7A4A4;
}

label.input-label.button-inside .button {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
}

label.input-label.button-inside .copied {
    position: absolute;
    top: 0;
    right: 65px;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 18px;
}

label.input-label .help {
    position: absolute;
    bottom: 27px;
    transform: translateY(50%);
    right: -35px;
    cursor: pointer
}


label.input-label .help img {
    pointer-events: none
}

label.input-label .input {
    padding-top: 22px
}

label.input-label .input:focus + .placeholder, label.input-label .input.filled + .placeholder {
    font-size: 12px;
    line-height: 150%;
    top: 12px;
    padding-top: 5px;
    right: 0;
    background: #fff;
    border-color: #2c4157; /* Цвет рамки при фокусе */
}

label.input-label .input.input-error + .placeholder {
    background: #F5D4D4; /* Красный фон при ошибке */
    /*color: #e74c3c; !* Красный цвет текста метки при ошибке *!*/
    border-radius: 4px; /* Добавьте скругление, если необходимо */
}

/* Дополнительные стили для метки при ошибке и фокусе */
label.input-label .input.input-error:focus + .placeholder,
label.input-label .input.input-error.filled + .placeholder {
    background: #F5D4D4; /* Убедитесь, что фон остается красным при фокусе */
    /*color: #e74c3c; !* Цвет текста *!*/
    border-color: #e74c3c; /* Красная рамка при ошибке и фокусе */
}


.input-error + .placeholder::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    /*background: #e74c3c; !* Линия под меткой при ошибке *!*/
}

label.input-label .placeholder {
    position: absolute;
    left: 16px;
    top: 27px;
    transition: all .2s ease-in-out;
    transform: translateY(-50%);
    color: #a7a4a4;
    font-size: 16px;
    line-height: 150%
}

.toggle {
    display: inline-flex;
    cursor: pointer
}

.toggle input {
    display: none
}

.toggle.checked .toggle__container,
.toggle input:checked + .toggle__container {
    background: #2c4157
}

.toggle.checked .toggle__container .toggle__circle,
.toggle input:checked + .toggle__container .toggle__circle {
    transform: translateY(-50%)
}

.toggle__container {
    position: relative;
    width: 35px;
    height: 20px;
    transition: all .2s ease-in-out;
    border-radius: 60px;
    background: #cbd1dd;
    pointer-events: none;
}

.toggle__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    transition: all .2s ease-in-out;
    transform: translate(-16px, -50%);
    background: #fff;
    pointer-events: none;
}

label.label-checkbox {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    cursor: pointer
}

label.label-checkbox input {
    display: none
}

/* 1 */

label.label-checkbox .psevdocheckbox {
    display: inline-flex;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 4px
}

.main-form-container {
    display: grid;
    grid-template-columns:repeat(2, 50%);
    align-items: stretch;
    width: 100%;
    background: #eaeef4
}

@media (max-width: 768px) {
    .main-form-container {
        grid-template-columns: 1fr; /* Одно колонное расположение */
    }
}

/* 2 */

.main-form__right {
    display: flex;
    flex-direction: column;
    align-items: stretch
}

.main-form__header {
    padding: 20px
}

.main-form__content {
    height: calc(100vh - 98px);
    border: 1px solid #b3c4db;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    overflow: auto;
    font-size: 18px;
    line-height: 142%;
    font-weight: 500
}

.main-form__content .scroll-wrap {
    width: 100%;
    max-width: 520px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: flex-start
}

.main-form__content .scroll-wrap h2, .main-form__content .scroll-wrap h3 {
    display: block;
    width: 100%;
    text-align: center
}

.main-form__content .scroll-wrap .button {
    width: 100%
}

.main-form__content .multiselect-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    width: 100% !important;
    min-height: 55px;
    border: none;
    outline: none;
    padding-left: 16px;
    background: #fff;
    font-size: 16px;
    line-height: 150%;
    border-radius: 0
}

.main-form__content .attorneys__list {
    display: flex;
    width: 100%;
    flex-direction: column
}

.main-form__content .attorneys__list .attorney {
    padding: 16px 0 !important;
    width: 100%;
    display: grid;
    grid-template-columns:104px 1fr 24px;
    align-items: flex-start;
    gap: 12px
}

.main-form__content .attorneys__list .attorney:before, .main-form__content .attorneys__list .attorney:after {
    display: none !important
}

.main-form__content .attorneys__list .attorney:not(:last-child) {
    border-bottom: 1px solid #fff
}

.main-form__content .attorneys__list .attorney .attorney__name {
    text-align: left
}

.main-form__content .attorneys__list .attorney .attorney__info {
    display: none
}

.main-form__content .attorneys__list .attorney .params__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px
}

.modal__form .content .params__list {
    display: flex;
    flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку */
    gap: 8px; /* Промежуток между элементами */
    list-style: none; /* Убираем маркеры списка */
    padding: 0;
    margin: 0;
}

.modal__form .content .params__list li {
    flex: 1 1 100%;
}

.main-form__content .attorneys__list .attorney .params__list li {
    background: #fff;
    padding: 3px 20px
}

.main-form__content .attorneys__list .attorney .button {
    display: flex;
    padding: 0;
    cursor: pointer
}

h1, h2, h3, h4, h5, h6 {
    margin: 0
}

h1 {
    color: #1d1d1d;
    font-size: 40px;
    line-height: 150%;
    font-weight: 500
}

@media only screen and (max-width: 1023px) {
    h1 {
        font-size: 36px
    }
}

h2 {
    color: #1d1d1d;
    font-size: 32px;
    line-height: 150%;
    font-weight: 500
}

@media only screen and (max-width: 1023px) {
    h2 {
        font-size: 28px
    }
}

@media only screen and (max-width: 779px) {
    h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;

    }
}

h3 {
    color: #1d1d1d;
    font-size: 22px;
    line-height: 150%;
    font-weight: 600
}

@media only screen and (max-width: 1023px) {
    h3 {
        font-size: 20px
    }
}

h4 {
    color: #1d1d1d;
    font-size: 20px;
    line-height: 100%;
    font-weight: 500
}

@media only screen and (max-width: 1023px) {
    h4 {
        font-size: 18px
    }
}

h5 {
    color: #1d1d1d;
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    text-transform: uppercase
}

h6 {
    color: #1d1d1d;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600
}

a:not(.button):hover {
    text-decoration: underline
}

a, button {
    cursor: pointer
}

*.fs-20 {
    font-size: 20px;
    line-height: 150%
}

*.c-gray {
    color: #a7a4a4
}

*.c-red {
    color: #d60000
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: transparent;
    padding: 15px 33px;
    cursor: pointer;
    font-size: 18px;
    line-height: 142%;
    line-height: 100%;
    transition: all .2s ease-in-out
}


.buttonExplainer:hover {
    background: #1d1d1d;
    border-color: #1d1d1d;
}

.buttonIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: currentColor;
}

.button.button-explainer {
    color: #fff;
    border: 1px solid #b3c4db;
}

.button.button-explainer:hover {
    color: #1d1d1d;
    background: #eaeef4
}


.button.button-border {
    color: #1d1d1d;
    border: 1px solid #b3c4db;
    background: #fff
}

.button.button-border:hover {
    background: #eaeef4
}

.button.button-black {
    color: #fff;
    border: 1px solid #2c4157;
    background: #2c4157
}

.button.button-black:hover {
    color: #1d1d1d;
    border: 1px solid #b3c4db;
    background: #eaeef4
}

.button.button-white {
    color: #2c4157;
    border: 1px solid #fff;
    background: #fff
}

.button.button-white:hover {
    color: #fff;
    border: 1px solid #2c4157;
    background: #2c4157
}

.button.button-red {
    color: #fff;
    border: 1px solid #D60000;
    background: #d60000
}

.button.button-red:hover {
    color: #1d1d1d;
    border: 1px solid #b3c4db;
    background: #eaeef4
}

.button:disabled, .button.disabled {
    opacity: .3;
    pointer-events: none;
    cursor: default
}

button.no-style,
.button.no-style {
    padding: 4px;
    background: transparent;
    border: none;
    outline: none;
    vertical-align: bottom;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 5px
}

.button.no-style:hover {
    background: #eaeef4
}

.buttons-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px
}

.buttons-group .button {
    width: calc(50% - 8px)
}

.header {
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    background: #ddd;
    display: flex;
    flex-direction: column;
    z-index: 20
}

.header.header-inner {
    top: 98px;
}

@media only screen and (max-width: 779px) {
    .header.header-inner {
        top: 108px;
    }
}

.header.micro .header__menu {
    background: #cad0dc
}

.header.micro .header__menu .logo .logo__img {
    opacity: 0;
    height: 0
}

.header.micro .header__menu .logo .logo__text {
    transform: scale(.75)
}

.header.micro .header__description, .header.micro .header__search, .header.micro .header__result {
    display: none
}

.header.min .header__menu {
    background: #cad0dc
}

@media only screen and (max-width: 779px) {
    .header.min .header__menu .container.justify {
        align-items: center
    }
}

.header.min .header__menu .logo .logo__img {
    opacity: 0;
    height: 0
}

.header.min .header__menu .logo .logo__text {
    transform: scale(.75)
}

.header.min .header__description {
    max-height: 0
}

.header.min .header__search .content {
    padding: 16px 0 24px
}

.header.min .header__search .search__input label.label .input {
    box-shadow: 0 0 80px #b7bed3;
    height: 72px
}

@media only screen and (max-width: 779px) {
    .header.min .header__search .search__input label.label .input {
        height: 40px
    }
}

.header.min .header__search .search__input label.label .placeholder {
    font-size: 32px
}

@media only screen and (max-width: 779px) {
    .header.min .header__search .search__input label.label .placeholder {
        font-size: 16px
    }
}

/* 3 */

.header__banner {
    display: block;
    max-height: 400px;
    transition: all .6s linear;
    background-size: cover !important;
}

@media only screen and (max-width: 779px) {
    .header__banner {
        position: relative;
        z-index: 20
    }
}

.header__banner.min .banner-min {
    opacity: 1;
    pointer-events: auto;
    transition-delay: .4s
}

.header__banner.min .banner-max {
    max-height: 0;
    opacity: 0
}

.header__banner .container {
    position: relative
}

.header__banner * {
    color: #fff
}

.header__menu {
    transition: all .6s linear;
    position: relative;
    background: #eaeef4
}

@media only screen and (max-width: 779px) {
    .header__menu {
        z-index: 5
    }
}

@media only screen and (max-width: 779px) {
    .header__menu.open .mobile-menu-wrap {
        max-height: 100vh !important
    }
}

.header__menu .container.justify {
    display: grid;
    grid-template-columns:calc(50% - 150px) 300px calc(50% - 150px);
    align-items: flex-start
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify {
        position: static;
        grid-template-columns: calc(50% - 90px) 180px calc(50% - 90px);
    }
}

.header__menu .container.justify *:last-child {
    justify-content: flex-end
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .left {
        height: 48px;
        display: inline-flex;
        align-items: center;
        position: static
    }
}

.header__menu .container.justify .hamburger {
    display: none;
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    background: transparent
}

.header__menu .container.justify .hamburger:before, .header__menu .container.justify .hamburger:after, .header__menu .container.justify .hamburger span {
    position: absolute;
    content: "";
    transition: all .2s linear;
    width: 16px;
    height: 2px;
    background: #1d1d1d;
    top: 50%;
    left: 50%
}

.header__menu .container.justify .hamburger:before {
    transform: translate(-50%, -5px)
}

.header__menu .container.justify .hamburger span {
    transform: translate(-50%, -50%)
}

.header__menu .container.justify .hamburger:after {
    transform: translate(-50%, 3px)
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .hamburger {
        display: inline-block
    }
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .mobile-menu-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        height: 100vh;
        background: #0000004d;
        max-height: 0;
        overflow: hidden;
        transition: all .3s linear
    }
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .mobile-menu-wrap ul.menu {
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 10px 0
    }
}

@media only screen and (max-width: 779px) and (max-width: 779px) {
    .header__menu .container.justify .mobile-menu-wrap ul.menu li a {
        padding: 34px 0;
        font-size: 20px;
        color: #1d1d1d
    }
}

.header__menu .container.justify .menu {
    display: inline-flex;
    align-items: center;
    gap: 15px
}

.header__menu .container.justify .menu.mobile {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .header__menu .container.justify .menu {
        gap: 20px
    }
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .menu {
        gap: 5px;
    }

    .header__menu .container.justify .menu.user a span {
        display: none
    }

    .header__menu .container.justify .menu.mobile {
        display: inline-block;
    }
}

.header__menu .container.justify .menu li {
    height: 48px;
    display: inline-flex;
    align-items: center
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .menu li.desktop {
        display: none;
    }
}

.header__menu .container.justify .menu li a {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    line-height: 100%;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #1D1D1D;
    padding: 3px 8px;
}

.header__menu .container.justify .menu li a.active {
    color: #fff;
    background: #2C4157;
}

@media only screen and (max-width: 1023px) {
    .header__menu .container.justify .menu li a.active {
        color: #527BA5 !important;
        background: transparent;
    }
}

.header__menu .container.justify .logo {
    display: inline-flex;
    flex-direction: column;
    align-items: center
}

.header__menu .container.justify .logo__img {
    height: 77px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    transition: height .6s linear, opacity .6s linear;
    transform: translateY(17px)
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .logo__img {
        height: 55px
    }
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .logo__img img {
        height: 100%;
        width: auto;
        transform: translateY(0)
    }
}

.header__menu .container.justify .logo__text {
    display: flex;
    height: 50px;
    align-items: center;
    transition: all .6s linear
}

@media only screen and (max-width: 779px) {
    .header__menu .container.justify .logo__text {
        height: 60px
    }
}

.header__description {
    transition: all .6s linear;
    max-height: 300px;
    overflow: hidden;
    background: #eaeef4
}

@media only screen and (max-width: 779px) {
    .header__description {
        max-height: 500px
    }
}

.header__description .content {
    padding: 2px 0 2px;
    display: flex;
    justify-content: space-between;
    gap: 32px
}

@media only screen and (max-width: 779px) {
    .header__description .content {
        flex-direction: column;
        padding: 20px 0 40px
    }
}

.header__description .content-left {
    width: calc(100% - 410px);
    justify-content: flex-start;
    text-align: left;
}

@media only screen and (max-width: 779px) {
    .header__description .content-left {
        width: 100%;
    }
}

.header__description .content-right {
    width: 370px;
    gap: 24px;
    display: flex;
    align-items: flex-start;
}

@media only screen and (max-width: 779px) {
    .header__description .content-right {
        width: 100%;
    }
}

.header__description .content-right .icons {
    display: inline-flex;
    width: 40px;
    height: 40px;
}

/* Добавленные стили для параграфа */
.header__description .content-right p {
    height: 170px; /* Фиксированная высота для десктопов */
    overflow: hidden; /* Скрытие переполненного текста */
    transition: height 0.3s ease; /* Плавный переход, если необходимо */
}

@media only screen and (max-width: 779px) {
    .header__description .content-right p {
        height: 140px; /* Фиксированная высота для мобильных устройств */
    }
}

.header__description .content-left p {
    font-size: 24px;
    line-height: 150%;
    color: #1d1d1d;
}

.header__description .content-right p {
    font-size: 20px;
    line-height: 34px;
    color: #1d1d1d;
    width: calc(100% - 70px)
}

@media only screen and (max-width: 1023px) {
    .header__description .content p {
        font-size: 20px
    }
}

.header__description .content a {
    color: #a7a4a4;
    font-size: 20px;
    line-height: 150%
}

.header__search {
    transition: all .6s linear;
    background: #eaeef4;
    border-bottom: 1px solid #ffffff;
    position: relative;
    z-index: 5;
}

@media only screen and (max-width: 779px) {
    .header__search {
        position: relative;
        z-index: 3
    }
}

.header__search .content {
    position: relative;
    transition: all .6s linear;
    padding: 32px 0 40px
}

@media only screen and (max-width: 779px) {
    .header__search .content {
        padding: 12px 0
    }
}

.header__search .search__notification {
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 600px;
    min-width: 400px;
    min-height: 50px; /* Минимальная высота */
    max-height: 180px; /* Максимальная высота */
    padding: 10px 15px;
    background: #2C4157;
    box-shadow: 0px -8px 32px rgba(59, 73, 90, 0.2);
    z-index: 10;
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    gap: 13px;
    border-radius: 5px;
    box-sizing: border-box;
    /*overflow: scroll;*/
}


@media only screen and (max-width: 779px) {
    .header__search .search__notification {
        min-width: 200px; /* Уменьшенная минимальная ширина для мобильных */
        max-width: 90%;
        top: 0;
        grid-template-columns: 20px 1fr 24px; /* Поддержка трех колонок */
    }
}

.header__search .search__notification > div,
.header__search .search__notification > div canvas {
    width: 20px !important;
    height: 20px !important;
}

.header__search .search__notification > div canvas {
    filter: light(1);
}

.header__search .search__notification p {
    flex: 1;
    margin: 0;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: unset;
}

@media only screen and (max-width: 779px) {
    .header__search .search__notification p {
        width: calc(100% - 13px)
    }
}

.header__search .search__notification button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__search .search__notification button img {
    width: 20px;
    height: 20px;
    pointer-events: none;
}

@media only screen and (max-width: 779px) {
    .header__search .search__notification {
        top: 0;
        width: 95%; /* Немного увеличиваем ширину на мобильных */
        max-width: 100%;
        min-width: 200px; /* Уменьшаем минимальную ширину для мобильных устройств */
        padding: 8px 12px; /* Уменьшаем padding для мобильных */
    }

    .header__search .search__notification p {
        font-size: 13px; /* Немного уменьшаем размер шрифта для мобильных */
    }
}

.header__search .search__input label.label {
    display: block;
    width: 100%;
    position: relative
}

.header__search .search__input label.label .input {
    display: block;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0 0 40px #b7bed366;
    font-size: 24px;
    line-height: 135%;
    font-weight: 350;
    color: #1d1d1d;
    padding-left: 92px;
    padding-right: 245px;
    transition: height .6s linear, font-size .6s linear
}

@media only screen and (max-width: 1023px) {
    .header__search .search__input label.label .input {
        font-size: 20px
    }
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .input {
        height: 40px;
        font-size: 16px;
        padding-left: 48px;
        padding-right: 135px
    }
}

/* 4 */

.header__search .search__input label.label .input.fill + .placeholder, .header__search .search__input label.label .input:focus + .placeholder {
    opacity: 0
}

.header__search .search__input label.label .input.fill + .placeholder + .buttons {
    opacity: 1;
    pointer-events: auto
}

.header__search .search__input label.label .placeholder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all .2s linear;
    color: #a7a4a4;
    font-size: 24px;
    line-height: 135%;
    font-weight: 350;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%
}

@media only screen and (max-width: 1023px) {
    .header__search .search__input label.label .placeholder {
        font-size: 20px
    }
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .placeholder {
        font-size: 16px
    }
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .placeholder img {
        width: 18px;
        height: 18px
    }
}

.header__search .search__input label.label .buttons {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s linear;
    pointer-events: none;
    right: 32px;
    gap: 20px;
    opacity: 0
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .buttons {
        gap: 14px;
        right: 8px
    }
}

.header__search .search__input label.label .buttons .clear {
    background: transparent;
    border: none;
    outline: none;
    transition: all .2s linear
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .buttons .clear img {
        width: 20px;
        height: 20px
    }
}

.header__search .search__input label.label .buttons .clear:hover {
    opacity: .7
}

.header__search .search__input label.label .buttons .button-black {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2c4157;
    color: #fff;
    font-size: 16px;
    line-height: 120%;
    transition: all .2s linear;
    min-height: 56px;
    min-width: 148px
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .buttons .button-black {
        min-height: 32px;
        min-width: 75px;
        font-size: 14px;
        height: auto !important;
    }
}

.header__search .search__input label.label .buttons .button-black:hover {
    opacity: .8
}

.header__search .search__input label.label .buttons .button-black * {
    pointer-events: none
}

.header__search .search__input label.label .buttons .button-black.pressed .pulled {
    display: none
}

.header__search .search__input label.label .buttons .button-black.pressed .pressed {
    display: block;
}

.header__search .search__input label.label .buttons .button-black .pressed {
    width: 38px !important;
    height: 38px !important;
}

@media only screen and (max-width: 779px) {
    .header__search .search__input label.label .buttons .button-black .pressed {
        width: 18px !important;
        height: 18px !important;
    }
}

.header__search .search__input label.label .buttons .button-black .loader {
    transform: translateZ(0) scale(.4)
}

.header__search .search__filters {
    display: flex;
    justify-content: space-between;
    gap: 30px
}

@media only screen and (max-width: 779px) {
    .header__search .search__filters {
        flex-direction: column;
        gap: 10px
    }
}

.header__search .search__filters .tabs-scroll-wrap {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #65758B transparent
}

.header__search .search__filters .search__tabs {
    display: flex;
    gap: 12px;
    min-width: 730px
}

.header__search .search__filters .search__tabs li {
    display: inline-flex;
    position: relative;
    color: #616161;
    font-size: 16px;
    line-height: 135%;
    font-weight: 350;
    padding-top: 20px;
    min-width: 52px;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap
}

.header__search .search__filters .search__tabs li > div,
.header__search .search__filters .search__tabs li > canvas {
    width: 25px !important;
    height: 25px !important;
}

.header__search .search__filters .search__tabs li > div,
.header__search .search__filters .search__tabs li > canvas {
    width: 25px !important;
    height: 25px !important;
}

@media only screen and (max-width: 779px) {
    .header__search .search__filters .search__tabs li {
        font-size: 12px;
        padding: 15px 0 5px
    }
}

.header__search .search__filters .search__tabs li:before {
    position: absolute;
    content: "";
    transition: all .2s linear;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ffffff transparent transparent transparent
}

.header__search .search__filters .search__tabs li.active {
    font-weight: 600;
    cursor: default
}

.header__search .search__filters .search__tabs li.active:before {
    opacity: 1
}

.header__search .search__side {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 17px;
    white-space: nowrap
}

@media only screen and (max-width: 779px) {
    .header__search .search__side {
        justify-content: space-between
    }
}

.header__search .search__adv {
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #1D1D1D;
    font-size: 16px;
    line-height: 110%;
    font-weight: 600;
    cursor: pointer;
    background: #f7fafa;
}

@media only screen and (max-width: 779px) {
    .header__search .search__adv {
        font-size: 12px
    }
}

.header__search .search__adv .points {
    position: relative;
    width: 16px;
    height: 16px;
    transform: translateY(-1px);
}

.header__search .search__adv .points .point {
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 4.6px;
    height: 4.6px;
    border-radius: 2px;
    background: #B3C4DB;
    left: 50%;
    top: 50%;
}

.header__search .search__adv .points .point.point1 {
    transform: translate(-5px, -5px);
}

.header__search .search__adv .points .point.point2 {
    transform: translate(2px, -5px);
}

.header__search .search__adv .points .point.point3 {
    transform: translate(-5px, 2px);
}

.header__search .search__adv .points .point.point4 {
    transform: translate(2px, 2px);
}

.header__search .search__adv.open .points .point.point1 {
    height: 4px;
    width: 15px;
    transform: translate(-50%, -50%) rotate(45deg);
}

.header__search .search__adv.open .points .point.point2 {
    height: 4px;
    width: 15px;
    transform: translate(-50%, -50%) rotate(135deg);
}

.header__search .search__adv.open .points .point.point3 {
    opacity: 0
}

.header__search .search__adv.open .points .point.point4 {
    opacity: 0
}

.header__search .search__radius {
    display: flex;
    gap: 3px;
}

.header__search .search__radius span {
    color: #1D1D1D;
    font-size: 16px;
    /*line-height: 150%;*/
    font-weight: 350;
}

@media only screen and (max-width: 779px) {
    .header__search .search__radius span {
        font-size: 12px
    }
}

.header__search .search__radius select {
    background: transparent;
    border: none;
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
}

@media only screen and (max-width: 779px) {
    .header__search .search__radius select {
        font-size: 12px
    }
}

.header__search .search__radius select option {
    padding: 3px 5px
}

.header__search .search__advanced {
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 0;
    display: none;
    grid-template-columns: 1fr 100px;
    gap: 16px;
}

@media only screen and (max-width: 779px) {
    .header__search .search__advanced .grid-container,
    .header__search .search__advanced {
        display: none;
        flex-direction: column !important;
        width: 100% !important;
    }
}

.header__search .search__advanced .grid-container {
    width: calc(100% - 16px);
    display: grid;
    grid-template-columns:repeat(4, calc(25% - 8px));
    gap: 16px;
}


@media only screen and (max-width: 1023px) {
    .header__search .search__advanced .grid-container {
        grid-template-columns:repeat(2, 1fr);
    }
}

@media only screen and (max-width: 779px) {
    .header__search .search__advanced .grid-container {
        overflow: hidden;
        transition: max-height 0.3s ease;
        display: block; /* Обязательно проверьте, чтобы display менялся на block */
        flex-direction: column;
        width: 100%;
    }
}


.header__search .search__advanced.show {
    display: grid;
    align-items: flex-start;
    z-index: 10
}

@media only screen and (max-width: 779px) {
    .header__search .search__advanced.show {
        max-height: 1600px; /* Или любое другое подходящее значение */
        overflow: visible;
        display: flex;
    }
}

.header__search .search__advanced.overflow-visible {
    overflow: visible
}

.header__search .search__advanced .column {
    padding-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px
}

@media only screen and (max-width: 779px) {
    .header__search .search__advanced .column {
        padding-top: 16px
    }
}

.header__search .search__advanced .column label.label {
    display: block;
    color: #616161;
    font-size: 16px;
    line-height: 120%
}

/* 5 */

.header__search .search__advanced .column .multiselect-dropdown {
    border: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 48px;
    border-radius: 0;
    padding: 0 0 0 48px;
    width: 100% !important
}

.header__search .search__advanced .column .multiselect-dropdown span.placeholder {
    color: #a7a4a4;
    font-size: 16px;
    line-height: 120%
}

.header__search .search__advanced .column .multiselect-dropdown span.optext {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    background: transparent;
    padding: 8px;
    color: #1d1d1d;
    font-size: 12px;
    line-height: 120%;
    font-weight: 600;
    margin: 0
}

.header__search .search__advanced .column .multiselect-dropdown span.optext span.optdel {
    color: #616161;
    font-size: 10px;
    margin: 0
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper {
    box-shadow: none;
    border: none;
    padding: 0
}

/* 6 */
.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper > input {
    min-height: 48px;
    border-radius: 0;
    padding: 0 0 0 48px;
    font-size: 16px;
    line-height: 120%;
    color: #1d1d1d;
    margin: 0
}


.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper > input::placeholder {
    color: #a7a4a4
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list {
    height: auto !important
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list div {
    display: flex !important;
    align-items: center;
    gap: 4px
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list div.checked input {
    background: #1d1d1d !important
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list div input {
    min-height: 20px;
    width: 20px;
    height: 2px;
    border: 1px solid #1d1d1d
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list div input:checked {
    background: #1d1d1d !important
}

.header__search .search__advanced .column .multiselect-dropdown .multiselect-dropdown-list-wrapper .multiselect-dropdown-list div label {
    color: #1d1d1d;
    font-size: 12px;
    line-height: 120%;
    font-weight: 600
}

.header__search .search__advanced .consultations-checkbox {
    display: flex;
    cursor: pointer;
}

.header__search .search__advanced .consultations-checkbox span {
    width: 50px;
    height: 48px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #1D1D1D;
    pointer-events: none;
}

.header__search .search__advanced .consultations-checkbox span:last-child {
    background: #fff;
}

.header__search .search__advanced .consultations-checkbox.checked span:first-child {
    background: #fff;
}

.header__search .search__advanced .consultations-checkbox.checked span:last-child {
    background: transparent;
}

.header__notification {
    background: #2c4157;
    padding: 12px 0;
    position: relative;
    z-index: 1000; /* Значение z-index должно быть больше, чем у попапа */
}

.header__notification-alert {
    background: #d60000;
    cursor: pointer;
    z-index: 1000; /* Значение z-index должно быть больше, чем у попапа */

}

.header__notification-alert .content {
    color: #fff;
    z-index: 1000; /* Значение z-index должно быть больше, чем у попапа */

}

.header__notification:hover .content .popup-notofication, .header__notification.show-notification .content .popup-notofication {
    pointer-events: auto;
    opacity: 1
}

.header__notification .content {
    display: flex;
    justify-content: center;
    gap: 10px;
    position: relative;
    background: #2c4157;

}

.header__notification .content .popup-notofication {
    position: absolute;
    display: inline-flex;
    align-items: center;
    max-width: 90vw;
    gap: 12px;
    padding: 8px 16px;
    background: #fff;
    z-index: 2000; /* Значение z-index должно быть больше, чем у попапа */
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -120%);
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out
}

.header__notification .content .popup-notofication img {
    width: 32px;
    height: 32px
}

.header__notification .content .popup-notofication span {
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%
}

.header__notification .content span {
    color: #fff;
    font-size: 16px;
    line-height: 120%
}

.header__notification .content span strong {
    font-weight: 600
}

.banner-min {
    position: absolute;
    transition: opacity .2s linear;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    opacity: 0;
    pointer-events: none;
    cursor: pointer
}

.banner-min .content {
    padding: 12px 0
}

.banner-min .banner__title img {
    width: 22px;
    height: 18px
}

.banner-min .banner__list .banner__title, .banner-min .banner__list .banner__date, .banner-min .banner__list .banner__link, .banner-min .banner__list .banner__title span, .banner-min .banner__list .banner__date span, .banner-min .banner__list .banner__link span {
    font-size: 20px;
    line-height: 120%
}

@media only screen and (max-width: 779px) {
    .banner-min ul.banner__list li.banner__date, .banner-min ul.banner__list li.banner__link {
        display: none
    }
}

@media only screen and (max-width: 779px) {
    .banner-min ul.banner__list li:after {
        display: none
    }
}

.banner-max {
    position: relative;
    justify-content: center;
    padding: 0;
    transition: all .6s linear;
    overflow: hidden;
    max-height: 400px;
}

.banner-max .content {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px
}

@media only screen and (max-width: 1023px) {
    .banner-max .content {
        gap: 40px
    }
}

@media only screen and (max-width: 779px) {
    .banner-max .content {
        gap: 12px;
        flex-direction: column
    }
}

.banner-max .banner__list {
    justify-content: flex-start
}

@media only screen and (max-width: 779px) {
    .banner-max .banner__list {
        justify-content: center
    }
}

.banner-max__logo {
    width: 320px
}

@media only screen and (max-width: 1023px) {
    .banner-max__logo {
        width: 240px
    }
}

@media only screen and (max-width: 779px) {
    .banner-max__logo {
        margin: 0 auto;
        max-width: 100%
    }
}

.banner-max__logo picture, .banner-max__logo img {
    max-width: 100%
}

.banner-max__text {
    width: calc(100% - 400px);
    display: flex;
    flex-direction: column;
    gap: 10px
}

@media only screen and (max-width: 1023px) {
    .banner-max__text {
        width: calc(100% - 280px)
    }
}

@media only screen and (max-width: 779px) {
    .banner-max__text {
        width: 100%;
        text-align: center
    }
}

.banner {
    color: #fff
}

.banner__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px
}

.banner__list li {
    position: relative
}

.banner__list li:not(:last-child):after {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 4px;
    top: 50%;
    right: -14px;
    transform: translate(-50%, -50%)
}

.banner__title {
    display: flex;
    align-items: center;
    gap: 16px
}

@media only screen and (max-width: 779px) {
    .banner__title {
        justify-content: center
    }
}

.banner__title img {
    width: 40px
}

@media only screen and (max-width: 779px) {
    .banner__title img {
        width: 30px
    }
}

.banner__title span {
    font-size: 32px;
    line-height: 150%;
    font-weight: 500
}

@media only screen and (max-width: 1023px) {
    .banner__title span {
        font-size: 28px
    }
}

@media only screen and (max-width: 779px) {
    .banner__title span {
        font-size: 16px
    }
}

@media only screen and (max-width: 779px) {
    .banner__title span {
        font-size: 20px
    }
}

.banner__date {
    font-size: 22px;
    line-height: 150%;
    font-weight: 600
}

@media only screen and (max-width: 1023px) {
    .banner__date {
        font-size: 20px
    }
}

@media only screen and (max-width: 779px) {
    .banner__date {
        font-size: 20px
    }
}

.banner__link {
    font-size: 22px;
    line-height: 150%;
    font-weight: 600
}

@media only screen and (max-width: 1023px) {
    .banner__link {
        font-size: 20px
    }
}

@media only screen and (max-width: 779px) {
    .banner__link {
        font-size: 20px
    }
}

.banner__description {
    font-size: 20px;
    line-height: 150%
}

@media only screen and (max-width: 779px) {
    .banner__description {
        font-size: 14px
    }
}

.banner__partners {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px
}

.banner__partners li picture, .banner__partners li img {
    width: 128px
}

.banner .button-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 44px;
    height: 44px;
    transition: all .2s linear;
    background: transparent;
    z-index: 10;
    border: none;
    outline: none
}

.banner .button-close:hover {
    opacity: .7
}

.banner .button-close img {
    width: 44px;
    height: 44px
}

.popup {
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    transition: all .2s ease-in-out;
    background: #fff;
    display: none
}


.popup.level-2 {
    z-index: 55
}

.popup.visible {
    display: block;
    opacity: 1;
    pointer-events: auto
}

.popup button.close {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: 10;
    right: 18px;
    top: 18px
}

.popup.popup-form {
    z-index: 70;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
    background: #fff;
    display: none
}

.popup.popup-form.visible {
    display: block;
    opacity: 1;
    pointer-events: auto
}

.popup.popup-form button.close {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: 10;
    right: 29px;
    top: 18px
}

.popup.popup-form .main-form-container {
    height: 100vh
}

.popup.popup-form .error-notification {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    top: 8px;
    background: #fff;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    max-width: 95vw
}

.modal {
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.modal.level-2 {
    z-index: 105
}

.modal.visible {
    display: block;
    opacity: 1;
    pointer-events: auto
}

.modal__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #01010180
}

.modal__content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    width: calc(100vw - 40px);
    max-width: 585px;
    background: #cbd1dd;
    padding: 12px;
    overflow: auto
}

.modal__content .button.close {
    position: absolute;
    top: 28px;
    right: 28px;
    border-radius: 100%;
    width: 32px !important;
}

.modal__content .button.close img {
    pointer-events: none
}

.modal__info {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%);
    background: #fff;
    padding: 16px;
    width: 715px;
    max-width: 95vw;
    display: grid;
    grid-template-columns:30px 1fr 30px;
    gap: 16px;
    font-size: 16px;
    line-height: 150%;
    z-index: 10;
    opacity: 0;
    pointer-events: none
}

.modal__info.visible {
    opacity: 1;
    pointer-events: auto
}

.modal__form {
    border: 2px solid #ffffff;
    min-height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 142%;
    padding: 24px;
    max-height: calc(95vh - 59px);
    overflow: auto;
}

.modal__form.large {
    min-height: 420px
}

#edit-schedule .modal__form .content {
    max-width: 490px;
}

.modal__form .content {
    max-width: 400px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: center
}

.modal__form .content > img {
    margin: 0 auto;
}

.modal__form .content.content-l {
    max-width: 480px
}

.modal__form .content > span {
    color: #1d1d1d
}

.modal__form .content .underline {
    cursor: pointer;
    font-size: 12px;
    line-height: 150%;
    border-bottom: 1px dashed currentColor
}

.modal__form .content h2, .modal__form .content h3, .modal__form .content .subtitle {
    display: block;
    width: 100%;
    text-align: center
}


.modal-share {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.modal__form .content .modal-share h2 {
    text-align: left;
}

.modal-share .modal-share__icons {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.modal-share .modal-share__icons .modal-share__icon {
    display: flex;
    flex-direction: column;
    position: relative;
}

.modal-share .modal-share__icons .modal-share__icon .copied {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 18px;
    min-width: 64px;
}

.modal-share .modal-share__icons .modal-share__icon img {
    width: 40px;
    display: block;
    margin: 0 auto;
}

.modal-share .modal-share__icons .modal-share__icon span {
    display: block;
    text-align: center;
    color: #616161;
    font-size: 11px;
}

.modal__form .content .button {
    width: 100%
}

.modal__form .multiselect-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    width: 100% !important;
    min-height: 55px;
    border: none;
    outline: none;
    padding-left: 16px;
    background: #fff;
    font-size: 16px;
    line-height: 150%;
    border-radius: 0
}

.modal__form .attorneys__list {
    display: flex;
    width: 100%;
    flex-direction: column
}

.modal__form .attorneys__list .attorney {
    padding: 16px 0 !important;
    width: 100%;
    display: grid;
    grid-template-columns:104px 1fr 24px;
    align-items: flex-start;
    gap: 12px
}

.modal__form .attorneys__list .attorney:before, .modal__form .attorneys__list .attorney:after {
    display: none !important
}

.modal__form .attorneys__list .attorney:not(:last-child) {
    border-bottom: 1px solid #fff
}

.modal__form .attorneys__list .attorney .attorney__name {
    text-align: left
}

.modal__form .attorneys__list .attorney .attorney__info {
    display: none
}

.modal__form .attorneys__list .attorney .params__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px
}

.modal__form .attorneys__list .attorney .params__list li {
    background: #fff;
    padding: 3px 20px
}

.modal__form .attorneys__list .attorney .button {
    display: flex;
    padding: 0;
    cursor: pointer
}

.avatar {
    width: 208px;
    /*height: 208px;*/
    border-radius: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center
}

@media only screen and (max-width: 779px) {
    .avatar {
        width: 100px;
        height: 100px
    }
}

.avatar picture, .avatar img {
    border-radius: 100%;
    max-width: 100%;
    max-height: 100%
}

.userpick__edit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    font-size: 0;
    cursor: pointer
}

.userpick__edit input {
    opacity: 0;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer
}

.userpick__edit img {
    position: relative;
    z-index: 5;
    pointer-events: none
}

.profile__header {
    padding: 15px 0;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    margin-bottom: 16px
}

.profile__header:last-child {
    margin-bottom: 0
}

@media only screen and (max-width: 1023px) {
    .profile__header {
        /*flex-direction: column;*/
        align-items: flex-start;

    }
}

.profile__header button.no-style {
    font-size: 14px;
    line-height: 150%;
    font-weight: 600;
    align-content: flex-start;
}

.profile__header .justify {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px
}

.profile__header .justify span.point {
    color: #a7a4a4
}

.profile__header .billing {
    padding-right: 24px
}

.profile__content {
    padding: 15px 0
}

.profile__headline {
    padding: 24px 0;
    display: flex;
    align-items: flex-end;
    background: none
}

.profile__headline .avatar, .profile__headline .container {
    position: relative
}

.profile__headline .container .bg__edit {
    position: absolute;
    top: 20px;
    right: 0;
    display: none
}

.profile__headline .container .bg__edit .button {
    pointer-events: none;
    border-radius: 50px;
    padding: 8px 16px;
    background: #fff;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center
}

.profile__headline .container .bg__edit input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer
}

.profile-pro .profile__headline {
    background-size: cover !important
}

.profile-pro .profile__headline .container {
    min-height: 232px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end
}

.profile-pro .profile__headline .bg__edit {
    display: inline-flex
}

/* 7 */

@media only screen and (max-width: 779px) {
    .profile-pro .profile__headline {
        height: 160px
    }
}

.profile__logo {
    position: relative;
    display: inline-block
}

.profile__logo,
.profile__logo picture,
.profile__logo img {
    max-height: 128px
}

.profile__logo .userpick__edit {
    top: -16px;
    right: -16px
}

@media only screen and (max-width: 779px) {
    .profile__info .vendor__header .vendor__name {
        font-size: 22px
    }
}

.profile__description {
    display: flex;
    flex-direction: column;
    gap: 35px;
    font-size: 18px;
    line-height: 142%;
    color: #1d1d1d
}

.profile__block:first-child {
    padding-top: 20px
}

.profile__block:last-child {
    padding-bottom: 20px
}

.profile__block:not(:last-child) {
    border-bottom: 8px solid #f0f5fc
}

@media only screen and (max-width: 779px) {
    .profile__block .vendor:not(:last-child) {
        margin-bottom: 20px
    }
}

@media only screen and (max-width: 779px) {
    .profile__block .vendor__header {
        margin-bottom: 5px
    }
}

.profile__contacts {
    position: relative;
    transform: translateY(-10px)
}

.profile__contacts .contacts {
    display: flex;
    flex-direction: column
}

.profile__contacts .contacts__el {
    display: grid;
    grid-template-columns:calc(42% - 4px) calc(58% - 4px);
    column-gap: 8px;
    padding: 9px 0
}

@media only screen and (max-width: 779px) {
    .profile__contacts .contacts__el {
        display: flex;
        padding: 16px 0;
        flex-direction: column
    }
}

.profile__contacts .contacts__el:not(:last-child) {
    border-bottom: 1px solid #EAEEF4
}

.modal .hours {
    margin-bottom: 16px;
}

.hours,
.profile__contacts .contacts__el .hours {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile__contacts .contacts__el .hours li {
    display: flex;
    gap: 8px;
    justify-content: space-between
}

.profile__contacts .contacts__el .social {
    display: flex;
    gap: 16px
}

.profile__contacts .contacts__el .social li a img {
    width: 40px
}

.profile__contacts .contacts__label {
    color: #616161;
    font-size: 16px;
    line-height: 150%
}

.profile__contacts .contacts__value {
    color: #1d1d1d;
    font-size: 18px;
    line-height: 142%
}

.profile__contacts .profile__share {
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 14px;
    transform: translateY(-100%);
}

@media only screen and (max-width: 779px) {
    .profile__contacts .profile__share {
        position: relative;
        top: auto;
        transform: translateY(0);
        margin-top: 30px;
        border: 1px solid #B3C4DB;
        height: 56px;
        width: 100%;
        flex-direction: row-reverse;
        font-size: 18px;
    }

    .profile__contacts .profile__share:before {
        position: absolute;
        content: "Share";
        left: 0;
        top: -26px;
        color: #616161;
        font-size: 16px;
        line-height: 150%;
    }
}

.profile__contacts .profile__share img {
    transform: translateY(-2px);
}

.profile .userinfo {
    display: grid;
    grid-template-columns:104px 1fr;
    align-items: center;
    gap: 24px;
    min-height: 104px
}

.profile .userinfo .userpick {
    position: relative;
    width: 104px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center
}


.profile .userinfo .userpick__img img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Изображение обрезается по размеру блока, сохраняя пропорции */
    border-radius: 50%; /* На всякий случай можно добавить и к изображению */
}


.profile .userinfo:not(:last-child) {
    margin-bottom: 24px
}

.profile .userinfo .profile__header {
    padding: 0
}


.profile .userinfo .userpick__img {
    max-width: 104px;
    max-height: 104px;
    border-radius: 100%
}

.profile .userdata {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 64px;
    padding-bottom: 30px;

}

@media only screen and (max-width: 779px) {
    .profile .userdata {
        flex-direction: column;
        gap: 0;
        flex: 1 1 50px;
    }
}

.profile .userdata .data {
    flex: 1 1 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
    color: #1d1d1d;
    font-size: 18px;
    line-height: 142%
}

.profile .userdata .data .no-description {
    color: #a7a4a4
}

.profile .userdata .data .add-info-button {
    margin-top: 10px;
}

.profile .userdata .contacts__list {
    display: flex;
    flex-direction: column
}

.profile .userdata .contacts__list.contacts__list-filled .contacts__el > div {
    grid-template-columns:270px 370px
}

.profile .userdata .contacts__list .contacts__el {
    padding: 6px 0;
    display: grid;
    align-items: center;
    grid-template-columns:1fr 30px;
    gap: 16px
}

.profile .userdata .contacts__list .contacts__el > div {
    display: grid;
    align-items: center;
    gap: 8px;
    grid-template-columns:70px 210px 5fr
}

.profile .userdata .contacts__list .contacts__el > div .label {
    font-size: 16px;
    line-height: 150%;
    color: #616161
}

.profile .userdata .contacts__list .contacts__el > div input.value {
    background: transparent;
    font-size: 18px;
    line-height: 142%;
    color: #1d1d1d
}

.profile .userdata .contacts__list .contacts__el > div .confirm {
    border: none;
    outline: none;
    padding: 4px;
    background: #2c4157;
    color: #fff;
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase
}

.profile .userdata .contacts__list .contacts__el > div .reconfirm {
    border: none;
    outline: none;
    padding: 4px;
    background: #d60000;
    color: #fff;
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase
}

.profile .userdata .contacts__list .contacts__el:not(:last-child) {
    border-bottom: 1px solid #eaeef4
}


@media only screen and (max-width: 779px) {
    .profile .userdata .contacts__list .contacts__el:not(:last-child) {
        border-bottom: 1px solid #eaeef4;
        padding-top: 40px;
    }
}

.profile-favourite span {
    font-size: 14px;
    line-height: 150%;
    color: #1d1d1d
}

.profile .settings {
    display: flex;
    flex-direction: column;
    gap: 32px
}

.profile .settings__block {
    display: flex;
    flex-direction: column;
    gap: 12px
}

.profile .settings__list {
    display: flex;
    flex-direction: column
}

.profile .settings__el {
    padding: 12px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    line-height: 150%
}

.profile .settings__el .change {
    background: white
}


.profile .settings__el:not(:last-child) {
    margin-bottom: 1px
}

.profile .params {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.profile .params__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px
}

.profile .params__list li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 142%;
    color: #1d1d1d;
    padding: 10px 20px;
    background: #f2f7fd
}

/* 8 */

.profile-rough {
    padding: 24px 0;
    color: #eaeef4;
    font-size: 20px;
    line-height: 150%
}

@media only screen and (max-width: 779px) {
    .profile-rough {
        font-size: 14px
    }
}

.profile-rough h2 {
    color: #eaeef4
}

.profile-rough .content {
    display: flex;
    align-items: center;
    gap: 48px
}

.profile-rough .content .text {
    max-width: 825px;
    display: flex;
    flex-direction: column
}

.profile__getpro {
    background: #2c4157;
    color: #eaeef4;
    padding: 20px 0
}

.profile__getpro * {
    color: #eaeef4
}

.profile__getpro .getpro__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    font-size: 16px;
    line-height: 150%
}

.profile__getpro .getpro__table {
    color: #eaeef4;
    font-size: 18px;
    line-height: 142%
}

.profile__getpro .getpro__table .vendor__table {
    width: 100%
}

.profile__getpro .getpro__table .vendor__table tr:first-child td {
    border: none
}

.profile__getpro .getpro__table .vendor__table tr:last-child td {
    border: none;
    padding-bottom: 0
}

.profile__getpro .getpro__table .vendor__table tr td {
    padding: 16px 0;
    border-bottom: 1px solid #b3c4db
}

.profile__getpro .getpro__table .vendor__table tr td:nth-child(1) {
    width: 50%
}

.profile__getpro .getpro__table .vendor__table tr td:nth-child(2), .profile__getpro .getpro__table .vendor__table tr td:nth-child(3) {
    width: 25%
}

.decor-list .decor-list__el {
    position: relative;
    padding: 32px
}


.decor-list .decor-list__el:after {
    position: absolute;
    content: "";
    height: 2px;
    background: #f0f5fc;
    bottom: 0
}

@media only screen and (max-width: 779px) {
    .decor-list .decor-list__el:after {
        display: none !important
    }
}

@media only screen and (max-width: 779px) {
    .decor-list .decor-list__el {
        padding: 0;
    }
}

/*.decor-list .decor-list__el:nth-child(1),
.decor-list .decor-list__el:nth-child(2) {
    padding-top: 0
}*/

.decor-list .decor-list__el:nth-child(odd) {
    padding-left: 0
}

@media only screen and (max-width: 779px) {
    .decor-list .decor-list__el:nth-child(odd) {
        padding-right: 0
    }
}

.decor-list .decor-list__el.vendor {
    border: none;
}

.decor-list .decor-list__el:nth-child(odd):before {
    position: absolute;
    content: "";
    width: 2px;
    background: #f0f5fc;
    right: -36px;
    top: 32px !important;
    bottom: 32px
}

@media only screen and (max-width: 779px) {
    .decor-list .decor-list__el:nth-child(odd):before {
        display: none !important
    }
}

.decor-list .decor-list__el:nth-child(odd):after {
    left: 0;
    right: 32px
}

@media only screen and (max-width: 779px) {
    .decor-list .decor-list__el:nth-child(odd):after {
        display: none !important
    }
}

.decor-list .decor-list__el:nth-child(1):before {
    top: 0
}

.decor-list .decor-list__el:nth-child(2n) {
    padding-right: 0
}

@media only screen and (max-width: 1023px) {
    .decor-list .decor-list__el:nth-child(2n) {
        padding-left: 0
    }
}

.decor-list .decor-list__el:nth-child(2n):after {
    left: 32px;
    right: 0
}

.attorneys {
    display: flex;
    flex-direction: column;
    gap: 32px
}

@media only screen and (max-width: 779px) {
    .attorneys {
        gap: 0
    }
}

.attorneys__list {
    display: grid;
    align-items: stretch;
    grid-template-columns:repeat(2, 50%)
}

.attorneys__list.attorneys__list-with-select .attorney {
    display: grid;
    grid-template-columns:20px 104px 1fr;
    align-items: center
}

.attorneys__list-no-avatar .attorney {
    display: flex !important;
    flex-direction: column
}

@media only screen and (max-width: 1023px) {
    .attorneys__list {
        display: flex;
        flex-direction: column;
        gap: 24px
    }
}

.attorneys .attorney {
    display: grid;
    grid-template-columns:104px 1fr;
    gap: 24px;
    position: relative;
    padding: 32px
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney {
        border-bottom: 2px solid #F0F5FC
    }
}

@media only screen and (max-width: 779px) {
    .attorneys .attorney {
        grid-template-columns:74px 1fr;
        padding: 24px 0 !important
    }
}

.attorneys .attorney:after {
    position: absolute;
    content: "";
    height: 2px;
    background: #f0f5fc;
    bottom: 0
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney:after {
        display: none !important
    }
}

.attorneys .attorney:nth-child(1), .attorneys .attorney:nth-child(2) {
    padding-top: 0
}

.attorneys .attorney:nth-child(odd) {
    padding-left: 0
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney:nth-child(odd) {
        padding-right: 0
    }
}

.attorneys .attorney:nth-child(odd):before {
    position: absolute;
    content: "";
    width: 2px;
    background: #f0f5fc;
    right: 0;
    top: 32px;
    bottom: 32px
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney:nth-child(odd):before {
        display: none !important
    }
}

.attorneys .attorney:nth-child(odd):after {
    left: 0;
    right: 32px
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney:nth-child(odd):after {
        display: none !important
    }
}

.attorneys .attorney:nth-child(1):before {
    top: 0
}

.attorneys .attorney:nth-child(2n) {
    padding-right: 0
}

@media only screen and (max-width: 1023px) {
    .attorneys .attorney:nth-child(2n) {
        padding-left: 0
    }
}

.attorneys .attorney:nth-child(2n):after {
    left: 32px;
    right: 0
}

.attorneys .attorney__content {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.attorneys .attorney__info {
    display: block;
    color: #616161;
    font-size: 14px;
    line-height: 150%;
    font-weight: 600
}

@media only screen and (max-width: 779px) {
    .attorneys .attorney .params__list {
        gap: 4px
    }
}

@media only screen and (max-width: 779px) {
    .attorneys .attorney .params__list li {
        padding: 8px;
        font-size: 12px
    }
}

.attorneys .attorney .button {
    display: none
}

.create-links {
    display: flex;
    flex-direction: column;
    gap: 16px
}

/* 9 */

.create-links .create-link {
    transition: all .2s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    line-height: 150%;
    padding: 32px 40px;
    min-height: 120px;
}

.create-links .create-link:hover {
    background-color: #eaeef4
}

.billing {
    display: flex;
    flex-direction: column;
    gap: 32px
}

.billing__section {
    display: flex;
    flex-direction: column;
    gap: 4px
}

.billing .card {
    display: inline-flex;
    align-items: center;
    gap: 8px
}

.billing .line {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between
}

.billing__left {
    width: 615px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%
}

.billing__right {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%
}

.billing__list {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.billing__el {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    padding: 16px 24px;
    background: #f0f5fc
}

.billing__el-trouble {
    background: #f0f0f0
}

.billing__el-error {
    background: #fcf0f0
}

.error-message {
    color: #1b1b1b;
}

main {
    position: relative
}

.no-result {
    padding: 50px 0;
    background: #eaedf4;
}

.no-result__content {
    max-width: 705px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
}

.no-result__icon {
    width: 64px;
    height: 64px;
}

.no-result__text {
    display: flex;
    flex-direction: column;
}

.no-result__text h2 {
    color: #1d1d1d;
    font-size: 4rem;
    line-height: 1.5;
}

.no-result__text p {
    color: #1d1d1d;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
}

.no-result__list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px
}

.no-result__el {
    display: inline-flex;
    background: #fff;
    border: none;
    outline: none;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 350;
    color: #616161;
    text-decoration: none;
    transition: all .2s linear
}

.no-result__el:hover {
    text-decoration: none !important;
    color: #1d1d1d;
    background: #eaeef4
}

.accordeon {
    height: 100%;
    position: relative
}

.accordeon.slow-position-animation .accordeon__header .header-content {
    transition: top .6s linear, background-color .2s linear, border-top-color .2s linear !important
}

.accordeon .accordeon__el:first-child .accordeon__header .header-content:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    height: 300px;
    top: -300px;
    background: #eaeef4
}

.accordeon .vendor:not(:last-child) {
    border-bottom: 2px solid #ffffff
}

.accordeon.hidden {
    opacity: 0
}

.header .accordeon__header {
    position: relative;
    z-index: 1;
    background: #eaeef4;
}

.header .accordeon__header .header-content {
    border-top: 1px solid #ffffff !important;
}

.header .accordeon__header:before {
    position: absolute;
    content: "";
    z-index: 1;
    background: #eaeef4;
    left: 0;
    right: 0;
    height: 15px;
    top: -15px;
}

.accordeon__header {
    height: auto;
}

.accordeon__header.top-fixed .header-content, .accordeon__header.bottom-fixed .header-content {
    position: fixed;
    left: 0;
    right: 0
}

.accordeon__header.top-fixed .header-content {
    border-top: 1px solid #ffffff;
    bottom: auto !important
}

.accordeon__header.bottom-fixed .header-content {
    border-top: 1px solid #eaeef4;
    top: auto !important;
    background: #fff
}

@media only screen and (max-width: 1023px) {
    .accordeon__header.bottom-fixed .header-content {
        background: transparent
    }
}

.accordeon__header.active:not(.top-fixed) .header-content {
    top: auto !important;
    bottom: auto !important
}

.accordeon__header.active .header-content {
    border-top: 1px solid #ffffff;
    height: 78px
}

@media only screen and (max-width: 779px) {
    .accordeon__header.active .header-content {
        height: 48px
    }
}

.accordeon__header.active .header-content__list li.show {
    display: none
}

.accordeon__header:not(.active) .header-content button {
    display: inline-flex
}

.accordeon__header .header-content {
    transition: background-color .2s linear, border-top-color .2s linear !important;
    background: #eaeef4;
    display: flex;
    align-items: center
}

.accordeon__header .header-content .icons {
    height: 30px;
    width: 30px;
}

@media only screen and (max-width: 1023px) {
    .accordeon__header .header-content {
        position: relative !important;
        min-height: 78px !important;
        border-top: 2px solid #ffffff !important;
        border-bottom: 2px solid #ffffff !important
    }
}

.accordeon__header .header-content .justify {
    display: flex;
    align-items: center;
    justify-content: space-between
}

@media only screen and (max-width: 1023px) {
    .accordeon__header .header-content .justify {
        flex-wrap: wrap;
        gap: 10px;
    }
}

.accordeon__header .header-content h4 {
    font-size: 30px;
    line-height: 1.5;
    font-weight: 600;
}

@media only screen and (max-width: 1023px) {
    .accordeon__header .header-content h4 {
        font-size: 26px
    }
}

@media only screen and (max-width: 779px) {
    .header .accordeon__el {
        display: none
    }

    .accordeon__header .header-content h4 {
        display: none;
        font-size: 24px;
    }

    .accordeon__header .header-content .law-firms h4 {
        display: block;
        font-size: 24px;
    }
}

.accordeon__header .header-content__list {
    display: flex;
    align-items: center;
    gap: 20px
}

.accordeon__header .header-content__list li {
    position: relative
}

.accordeon__header .header-content__list li span.counter {
    color: #1D1D1D;
    font-size: 16px;
    line-height: 150%;
    font-weight: 350
}

.accordeon__header .header-content__list li.show {
    position: relative
}

.accordeon__header .header-content__list li.show:before {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #616161;
    top: 50%;
    left: -8px;
    transform: translate(-50%, -50%)
}

.accordeon__header .header-content__list li.show button {
    color: #1d1d1d;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    border: none;
    background: transparent;
    outline: none
}

.accordeon__content {
    padding-top: 20px;
    padding-top: 0
}

.accordeon__content ul {
    display: flex;
    flex-direction: column
}

.show-all {
    padding: 24px 0;
    text-align: center;
    color: #616161;
    font-size: 16px;
    line-height: 150%
}

.show-all button {
    background: transparent;
    border: none;
    outline: none
}

.vendor {
    padding: 14px 0;
    border-top: 1px solid #fff;
}

.vendor.vendor-award {
    display: flex;
    align-items: center;
    gap: 24px;
}

@media only screen and (max-width: 779px) {
    .vendor {
        padding: 20px 0;
    }
}

.vendor.vendor-award .vendor__award,
.vendor.vendor-award .vendor__award img {
    width: 105px;
}

.vendor.vendor-award .vendor__award + .vendor__header {
    width: calc(100% - 130px);
}

.vendor-verified {
    background: #fff;
    border-top: 1px solid #eaeef4;
    /*border-top: 1px solid #b3c3db;*/

}

/* 10 */

.vendor-advertise {
    background: #000000;
    position: relative;
    border-top: 1px solid #fff;
    padding: 10px 0;
}

@media only screen and (max-width: 779px) {
    .vendor-advertise {
        padding: 32px 0
    }
}

.vendor-advertise:after {
    position: absolute;
    content: "ADW";
    top: 8px;
    right: 15px;
    background: rgba(242, 247, 253, 0.33);
    font-size: 18px;
    padding: 7px 15px;
    color: #fff;
}

@media only screen and (max-width: 1023px) {
    .vendor-advertise:after {
        right: 8px;
    }
}

@media only screen and (max-width: 779px) {
    .vendor-advertise:after {
        padding: 6px 13px;
    }
}

.vendor-advertise .vendor__name, .vendor-advertise .vendor__attorney, .vendor-advertise .vendor__description, .vendor-advertise .vendor__link, .vendor-advertise .vendor__label,
.vendor-day .vendor__name, .vendor-day .vendor__attorney, .vendor-day .vendor__description, .vendor-day .vendor__link, .vendor-day .vendor__label {
    color: #fff
}

@media only screen and (max-width: 1023px) {
    .vendor.vendor-day .justify {
        display: grid;
        grid-template-columns: 1fr 370px;
    }
}


@media only screen and (max-width: 779px) {
    .vendor.vendor-day .justify {
        display: flex;
        flex-direction: column;
    }
}

.vendor-day .vendor__data {
    width: calc(100% - 680px)
}

@media only screen and (max-width: 1023px) {
    .vendor-day .vendor__data {
        width: 100%
    }

    .vendor.vendor-day .vendor__logo {
        grid-column-start: 1;
    }

    .vendor.vendor-day .vendor__data {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }
}


.vendor-advertise .justify {
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .vendor.vendor-advertise .justify {
        display: grid;
        grid-template-columns: 1fr 280px;
    }
}

@media only screen and (max-width: 779px) {
    .vendor.vendor-advertise .justify {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 23px;
    }
}

@media only screen and (max-width: 1023px) {
    .vendor.vendor-advertise .vendor__data {
        width: 100% !important;
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.vendor-advertise .vendor__logo {
    border: 1px solid rgba(240, 245, 252, 0.5);
    overflow: hidden;
}

.vendor-day .favorites svg,
.vendor-day .verified img,
.vendor-advertise .favorites svg,
.vendor-advertise .verified img {
    width: 32px !important;
    height: 32px !important;
}

@media only screen and (max-width: 779px) {
    .vendor-day .favorites svg,
    .vendor-day .verified img,
    .vendor-advertise .favorites svg,
    .vendor-advertise .verified img {
        width: 20px !important;
        height: 20px !important;
    }
}

.vendor-advertise .vendor__name {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
}

@media only screen and (max-width: 1023px) {
    .vendor-advertise .vendor__name {
        font-size: 36px;
    }

}

@media only screen and (max-width: 779px) {
    .vendor-advertise .vendor__header {
        margin-bottom: 0;
    }

    .vendor-advertise .vendor__name {
        font-size: 22px;
    }
}

.vendor-advertise .vendor__description {
    font-size: 20px;
    line-height: 1.35;
}

@media only screen and (max-width: 779px) {
    .vendor-advertise .vendor__description {
        font-size: 16px;
    }
}

.vendor-advertise .vendor__contacts .row:first-child a {
    padding-right: 50px;
}

.vendor-advertise .social {
    display: flex;
    flex-direction: row;
    gap: 16px
}

.vendor-advertise .social li a img {
    width: 32px
}

@media only screen and (max-width: 779px) {
    .vendor-advertise .social li a img {
        width: 40px;
    }
}

.vendor .justify {
    display: flex;
    justify-content: space-between;
    gap: 40px
}

@media only screen and (max-width: 779px) {
    .vendor .justify {
        flex-direction: column;
        gap: 20px
    }
}

.vendor__data {
    width: calc(100% - 410px);
    max-width: 770px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer
}

.vendor.vendor-advertise .vendor__data,
.vendor.vendor-advertise .vendor__data {
    width: calc(100% - 678px);
}

@media only screen and (max-width: 779px) {
    .vendor__data {
        width: 100%;
        gap: 4px
    }
}

.vendor__contacts {
    width: 370px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 3px;
}

@media only screen and (max-width: 1023px) {
    .vendor__contacts {
        width: 280px
    }

}

@media only screen and (max-width: 779px) {
    .vendor__contacts {
        width: 100%
    }

    .vendor-advertise .vendor__contacts {
        padding-top: 0;
    }
}

.vendor__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
    row-gap: 4px
}

@media only screen and (max-width: 779px) {
    .vendor__header {
        /*padding-right: 30px;*/
        position: relative;
    }
}

.vendor__header .favorites {
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    height: 20px
}

@media only screen and (max-width: 779px) {
    .vendor__header .favorites {
        position: absolute;
        top: 4px;
        right: 0;
    }
}

.vendor__header .favorites.added svg path, .vendor__header .favorites:hover svg path {
    fill: currentColor
}

.vendor__header .favorites svg {
    width: 20px;
    height: 20px;
    transition: all .2s linear;
    color: #b3c4db
}

.vendor__header .favorites svg path {
    transition: all .2s linear;
    stroke: currentColor;
    fill: transparent;
    transform: scale(.9) translate(1px)
}

.vendor__name {
    display: inline-block;
    letter-spacing: .5px;
    font-size: 22px;
    line-height: 1.35;
    font-weight: 600;
}

@media only screen and (max-width: 779px) {
    .vendor__name {
        font-size: 20px;
    }
}

.vendor__day {
    background: #fff3;
    font-size: 12px;
    line-height: 135%;
    font-weight: 600;
    color: #fff !important;
    padding: 4px 8px
}

.vendor__attorney {
    display: block;
    width: 100%;
    color: #616161;
    font-size: 14px;
    line-height: 100%;
    font-weight: 500
}

@media only screen and (max-width: 779px) {
    .vendor__attorney {
        line-height: 150%;
    }
}

.vendor__description {
    display: block;
    color: #616161;
    font-size: 16px;
    line-height: 160%;
    padding-top: 10px;
}

@media only screen and (max-width: 779px) {
    .vendor__description {
        padding-top: 0;
        font-size: 14px;
        line-height: 1.35;
    }
}

.vendor__contacts .row {
    display: flex;
    flex-direction: column
}

.vendor__link {
    color: #1d1d1d;
    font-size: 22px;
    line-height: 120%
}

@media only screen and (max-width: 779px) {
    .vendor__link {
        font-size: 20px
    }
}

.vendor__label {
    color: #616161;
    font-size: 14px;
    line-height: 120%
}

.vendor__logo {
    cursor: pointer;
    width: 228px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 779px) {
    .vendor__logo {
        width: calc((100vw - 30px) * 0.57);
        height: calc((100vw - 30px) * 0.32);
    }
}

.vendor__logo picture,
.vendor__logo picture img {
    max-width: 100%;
    max-height: 100%;
}

.vendor__logo img {
    max-height: 456px;
    max-width: 256px;
}

@media only screen and (max-width: 779px) {
    .vendor__logo img {
        max-width: calc(((100vw - 30px) * 0.6) * .7);
        max-height: calc(((100vw - 30px) * 0.33) * .7);
    }
}

.vendor__status {
    padding: 4px 8px 2px;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 150%;
    font-weight: 600
}

.vendor__status-oncheck {
    background: #b3c4db
}

.vendor__status-active {
    background: #2c4157
}

@media only screen and (max-width: 779px) {
    .vendor__status-active {
        display: inline-block;
        white-space: nowrap;
    }
}

.checkbox__link {
    color: #1d1d1d;
    font-size: 18px;
}

@media only screen and (max-width: 779px) {
    .checkbox__link {
        font-size: 20px
    }
}

/* Переключатель Free Consultation */
.free-consultation-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 16px;
    background-color: #f0f0f0;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
}

.free-consultation-toggle .toggle {
    position: relative;
    display: flex;
    align-items: center;

}

.toggle__container {
    position: relative;
    width: 35px;
    height: 20px;
    background: #cbd1dd;
    border-radius: 60px;
    transition: background 0.2s ease-in-out;
}

.toggle.checked .toggle__container {
    background: #2c4157;
}

.toggle__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
    transform: translate(-16px, -50%);
    transition: transform 0.2s ease-in-out;
}

.toggle.checked .toggle__circle {
    transform: translate(16px, -50%);
}

.free-consultation-toggle span {
    font-size: 16px;
    color: #1D1D1D;
    flex-grow: 1;
}

.toggle:hover .toggle__container {
    background: #a0aab8;
}

/* schedule */

.schedule {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.schedule .column {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 4px
}

@media only screen and (max-width: 779px) {
    .schedule .column {
        padding-top: 16px
    }
}

.schedule .column label.label {
    display: block;
    color: #616161;
    font-size: 16px;
    line-height: 120%
}

.schedule li:not(.schedule__el) .button {
    width: auto;
    display: inline-flex;
    font-size: 14px;
}

.schedule .schedule__el {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 120px 120px 10px 10px;
    gap: 10px;
    width: 100%;
}

.schedule .schedule__el .input-label:first-child {
    padding-right: 10px;
}

.schedule .schedule__el .button {
    width: 24px !important;
    padding: 0 !important;
    background: transparent;
}

.schedule .schedule__el .input-label {
    position: relative;
}

.schedule .schedule__el .input-label .placeholder {
    position: absolute;
    font-size: 11px;
    line-height: 14px;
    color: #A7A4A4;
    left: 15px;
    top: 10px;
    z-index: 10;
}

.schedule .hyphen {
    color: #fff;
    text-align: center;
    width: 20px;
}

.custom-schedule-modal {
    max-width: 550px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.custom-schedule-modal .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 500px;
    margin: auto;
}

.custom-schedule-modal .modal__header {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.custom-schedule-modal .modal__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.registration-toggle {
    display: flex;
    justify-content: flex-start; /* Изменено с space-between на flex-start для правильного расположения */
    align-items: center; /* Обеспечивает вертикальное выравнивание */
    cursor: pointer;
    gap: 1px; /* Расстояние между элементами */
    margin-bottom: 16px;
    /* background-color: #f0f0f0; */ /* Фон отключен */
    padding: 1px 1px;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
}

.registration-toggle label {
    display: flex;
    align-items: center;
    gap: 20px; /* Устанавливает расстояние между кнопкой и текстом */
    cursor: pointer; /* Сохраняет курсор-указатель при наведении */
}

.registration-toggle label .toggle__container,
.registration-toggle label span {
    margin: 0;
    padding: 0;
}

.registration-toggle .toggle {
    position: relative;
    display: flex;
    align-items: center;
}

.button-row {
    display: flex;
    gap: 15px; /* Optional: Adds space between buttons */
}

.save-draft-button {
    flex: 0 0 40%;
    /* Alternatively, you can use width: 33%; */
}

.create-profile-button {
    flex: 0 0 60%;
    /* Alternatively, you can use width: 67%; */
}

/* Контейнер для переключателя биллинга */
.billing-toggle-container {
    display: flex;
    justify-content: space-between; /* Размещает левую и правую части по краям */
    align-items: center; /* Вертикальное выравнивание */
    margin: 10px 0; /* Уменьшенные внешние отступы */
    padding: 0 10px; /* Уменьшенные внутренние отступы */
    width: 100%; /* Контейнер занимает всю доступную ширину */
    box-sizing: border-box; /* Включает padding в ширину */
}

.billing-toggle {
    display: flex;
    align-items: center;
    gap: 5px; /* Уменьшенное расстояние между текстами и переключателем */
}

.billing-text {
    font-size: 16px; /* Увеличенный размер шрифта */
    color: #a7a4a4; /* Неактивный цвет текста */
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.billing-text.active {
    color: #2c4157; /* Яркий цвет текста */
    font-weight: 600;
}

.billing-text.inactive {
    color: #a7a4a4;
}

/* Стиль для ценового ярлыка */
.billing-prices {
    display: flex;
    gap: 10px; /* Ещё меньшее расстояние между ценами */
    align-items: center; /* Вертикальное выравнивание цен */
    margin-left: auto; /* Выталкивает цены к правому краю */
}

.billing-price {
    font-size: 16px; /* Увеличенный размер шрифта */
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.billing-price.active {
    color: #2c4157; /* Яркий цвет активной цены */
    text-decoration: none;
    font-weight: 600;
}

.billing-price.inactive {
    color: #a7a4a4;
    text-decoration: line-through; /* Перечёркивание неактивной цены */
}

/* Стили для переключателя */
.toggle {
    display: inline-flex;
    cursor: pointer;
}

.toggle__container {
    position: relative;
    width: 30px; /* Уменьшенная ширина */
    height: 16px; /* Уменьшенная высота */
    background: #a0aab8; /* Неактивный фон */
    border-radius: 50px; /* Более округлые края */
    transition: background-color 0.3s ease;
}

.toggle__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px; /* Уменьшенный диаметр */
    height: 12px; /* Уменьшенный диаметр */
    background: #fff;
    border-radius: 50%;
    transform: translate(-14px, -50%); /* Скорректировано смещение */
    transition: transform 0.3s ease;
}

.toggle.checked .toggle__container {
    background: #2c4157; /* Активный фон */
}

.toggle.checked .toggle__circle {
    transform: translate(14px, -50%); /* Скорректировано смещение */
}

.billing-toggle:hover .toggle__container {
    background: #90aab8; /* Немного изменённый оттенок при наведении */
}

/* Адаптивные стили */
@media only screen and (max-width: 779px) {
    .billing-toggle-container {
        /*flex-direction: column; !* Элементы располагаются вертикально на маленьких экранах *!*/
        align-items: flex-start; /* Выравнивает элементы по левому краю */
        gap: 15px; /* Уменьшенный промежуток на маленьких экранах */
        padding: 0 10px; /* Уменьшенные внутренние отступы */
    }

    .billing-toggle {
        gap: 5px; /* Уменьшенное расстояние между элементами */
    }

    .billing-prices {
        gap: 8px; /* Ещё меньшее расстояние между ценами */
        margin-left: 0; /* Убираем выталкивание на маленьких экранах */
    }

    .billing-price {
        font-size: 14px; /* Дополнительно увеличенный размер шрифта на маленьких экранах */
    }

    .billing-price.active {
        font-size: 14px;
    }

    .billing-text {
        font-size: 14px; /* Дополнительно увеличенный размер текста */
    }

    .toggle__container {
        width: 25px; /* Ещё более уменьшенная ширина */
        height: 14px; /* Ещё более уменьшенная высота */
    }

    .toggle__circle {
        width: 10px; /* Ещё более уменьшенный диаметр */
        height: 10px; /* Ещё более уменьшенный диаметр */
        transform: translate(-12px, -50%); /* Корректировка смещения */
    }

    .toggle.checked .toggle__circle {
        transform: translate(12px, -50%); /* Корректировка смещения */
    }
}

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px inherit inset; /* Наследуем цвет фона поля */
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
    -webkit-text-fill-color: inherit; /* Наследуем цвет текста */
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}


/* Убираем фон плейсхолдера при фокусе на input внутри input-attorney */
.input-label.input-attorney .input:focus + .placeholder {
    background: transparent;
}

/* Стилизация контейнера для чекбокса */
.custom-checkbox {
    display: inline-block;
    position: relative;
    width: 20px; /* Увеличиваем размер */
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 8px; /* Закругленные углы */
    cursor: pointer;
    background-color: white; /* Фон по умолчанию */
    transition: background-color 0.2s ease;
}

.custom-checkbox input {
    display: none; /* Скрываем стандартный чекбокс */
}

.custom-checkbox::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s ease;
}

/* Стиль для активного состояния чекбокса */
.custom-checkbox input:checked + .custom-checkbox::before {
    background-color: white; /* Галочка белого цвета */
}

/* Фон чекбокса при выборе */
.custom-checkbox input:checked + .custom-checkbox {
    background-color: #007bff; /* Синий фон */
    border-color: #007bff;
}

.custom-checkbox input:checked + .custom-checkbox::before {
    content: '\2714'; /* Галочка Unicode */
    font-size: 14px;
    color: white;
}

/* Увеличение высоты и ширины */
.custom-checkbox {
    width: 24px;
    height: 24px;
}

.title.loading {
    display: inline-block; /* Позволяет задавать фиксированную ширину */
    text-align: left; /* Выравнивание текста по левому краю */
    white-space: nowrap; /* Предотвращает перенос текста на новую строку */
    width: 300px; /* Фиксированная ширина для предотвращения смещения */
    overflow: hidden; /* Скрывает переполнение по горизонтали */
    vertical-align: top; /* Выравнивание по верхнему краю, если необходимо */
    padding-bottom: 2px; /* Увеличенный нижний отступ */

}

/* Стили для контейнера видео */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* Соотношение сторон 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Стили для метки при отключенном поле */
label.input-label .input:disabled + .placeholder {
    color: #999999; /* Серый цвет для метки */
}

/* Если поле отключено и заполнено */
label.input-label .input:disabled.filled + .placeholder {
    font-size: 12px;
    line-height: 150%;
    top: 12px;
    padding-top: 5px;
    right: 0;
    background: #f0f0f0; /* Фон метки может соответствовать фону отключенного поля */
    color: #999999; /* Серый цвет для метки */
}

.input:disabled {
    background-color: #f0f0f0; /* Светло-серый фон */
    color: #999999; /* Серый текст */
    cursor: not-allowed; /* Курсор в виде запрещающего знака */
    /* Удалите обводку или измените ее цвет, если необходимо */
    border-color: #cccccc;
    /* Другие стили по вашему желанию */
}

.header__banner.fade-in {
    animation: fadeInBanner 0.5s ease forwards;
    opacity: 0; /* изначально 0, а в конце анимации станет 1 */
}

@keyframes fadeInBanner {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
