.p-dropdown {
    height: 48px;
    padding: 10px 15px;
    background: #fff;
    width: 100%;
    position: relative;
}

.p-dropdown-label-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 30px);
}

.p-dropdown-label-container .p-placeholder {
    padding-left: 15px;
    font-size: 16px;
    overflow: visible;
}

/* 11 */

.p-dropdown-label-container .p-dropdown-label .p-dropdown-token:first-child {
    margin-left: 43px;
    position: relative;
}

.p-dropdown-label-container .p-dropdown-label .p-dropdown-token:first-child:before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: -31px;
    width: 18px;
    height: 18px;
    background-size: 18px 18px !important;
}

.p-disabled .p-dropdown-label-container {
    color: #A7A4A4;
}

.p-icon.p-dropdown-filter-icon {
    display: none;
}

.p-dropdown-token {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 120%;
    color: #1D1D1D;
    font-weight: 500;
    background: #F2F7FD;
}

.p-dropdown-token:not(:last-child) {
    margin-right: 4px
}

.p-dropdown-token svg path {
    fill: #A4A9B0;
}

.p-dropdown-label.p-dropdown-items-label {
    font-size: 12px;
    line-height: 120%;
    color: #1D1D1D;
    font-weight: 500;
}

.p-dropdown-panel {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin-top: 2px;
}

.p-dropdown-header {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    align-items: center;
    align-content: center;
    padding: 10px;
    min-height: 44px;
}

.p-dropdown-filter-container {
    font-size: 12px;
    line-height: 120%;
    height: 18px;
}

.p-dropdown-items-wrapper {
    max-height: 40vh !important;
    scrollbar-width: thin;
    scrollbar-color: #B3C4DB #EAEEF4;
}

@media only screen and (max-width: 779px) {
    .p-dropdown-items-wrapper {
        height: 40vh !important;
    }
}

.p-dropdown-items.p-component {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.p-dropdown-select-all {
    position: relative;
}

.p-dropdown-empty-message,
.p-dropdown-item {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    align-items: center;
    align-content: center;
    position: relative;
    padding: 10px;
    min-height: 44px;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    justify-content: flex-end;
}

.p-dropdown-item.p-highlight {
    position: relative;
    color: #1D1D1D;
}

.p-dropdown-item.p-highlight:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../../../../public/assets/check.svg) no-repeat 50% 50%
}

.p-dropdown-item .p-dropdown-checkbox {
    display: inline-block;
    position: relative;
}

.p-dropdown-item > span {
    font-size: 16px;
    line-height: 1.2;
    width: calc(100% - 20px);
}

.p-dropdown-select-all input.p-checkbox-input,
.p-dropdown-checkbox input.p-checkbox-input {
    opacity: 0;
}

.p-dropdown-select-all .p-checkbox-box,
.p-dropdown-item .p-checkbox-box {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    align-content: center;
    pointer-events: none;
}

.p-dropdown-select-all .p-checkbox-box {
    border: 1px solid #616161;
}


.p-dropdown-token-icon {
    width: 12px;
    height: 12px;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M9.21953%201.62981L5.84899%205.00035L9.21963%208.37098C8.96906%208.68423%208.68435%208.96896%208.37111%209.21953L5.00046%205.84888L1.62977%209.21957C1.31654%208.969%201.03181%208.68427%200.78125%208.37104L4.15193%205.00035L0.781346%201.62976C1.03192%201.31653%201.31665%201.03181%201.62989%200.78125L5.00046%204.15182L8.371%200.781291C8.68423%201.03186%208.96896%201.31658%209.21953%201.62981Z%22%20fill%3D%22%23A4A9B0%22/%3E%3C/svg%3E');
    background-size: cover;
    display: inline-block;
    background-position: bottom center;
    fill: none;
}

.p-dropdown-token-icon path {
    display: none; /* Убираем старую иконку */
}

.placeholder + .p-dropdown {
    padding-top: 20px;
}

.p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.2;
}

.p-dropdown .p-dropdown-trigger {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.p-dropdown .p-dropdown-trigger svg {
    transform: scale(1.2);
}

.p-dropdown .p-dropdown-trigger svg path {
    color: #B9B9B9
}