.load-files {
    width: 100%;
}

.load-files .input-label {
    width: 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;
    background: #ffffff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.load-files .input-label input {
    opacity: 0;
    position: absolute;
    inset: 0;
    cursor: pointer;
}

.load-files .input-label > img,
.load-files .input-label > span {
    pointer-events: none;
    color: #1D1D1D;
}

.load-files .input-label > span {
    font-size: 1.8rem;
    line-height: 2.44rem;
}

.files {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.files .file {
    padding: 8px 0;
    display: grid;
    align-items: center;
    gap: 8px;
    grid-template-columns: 34px 1fr 34px;
}

.files .file:not(:last-child) {
    border-bottom: 1px solid #a6aab2;
}

.files .file__icon {
    width: 34px;
    height: 34px;
}

.files .file__icon img {
    max-width: 100%;
    max-height: 100%;
}

.files .file__content {
    display: flex;
    flex-direction: column;
}

.files .file__name {
    color: #1D1D1D;
    font-size: 1.6rem;
    line-height: 1.2;
}

.files .file__size {
    color: #727270;
    font-size: 1.6rem;
    line-height: 1.2;
}

.files .file__remove {
    padding: 0;
    background: transparent;
    border:  none;
    cursor: pointer;
}

.files .file__remove img {
    width: 34px;
    filter: grayscale(1);
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}

.files .file__remove:hover img {
    opacity: 0.8;
}