/* IncompleteProfilePrompt.css */

/* Главный контейнер */
.frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
    /* Убираем фиксированную высоту для адаптивности */
}

/* Разделитель */
.divider {
    border-bottom: 1px solid #f0f5fc;
}

/* Баннеры */
.banners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 40px 24px 40px;
    gap: 16px;
    position: relative;
    width: 100%;
    background: #2C4157;
}

/* Октагон профиля */
.profile-octagon {
    position: absolute;
    width: 120px;
    height: 127.06px;
    right: 24px;
    bottom: -26.06px;
    opacity: 0.4;
    z-index: 0;
}

/* Основной фрейм */
.frame-1948756809 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    width: 100%;
    z-index: 1;
}

/* Внутренний фрейм */
.frame-1948756808 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

/* Заголовок */
.frame-1948756808 h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    color: #EAEEF4;
    margin: 0;
}

/* Описание */
.frame-1948756808 p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #EAEEF4;
    margin: 0;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .banners {
        padding: 24px 16px;
    }

    .profile-octagon {
        display: none; /* Скрываем на мобильных устройствах */
    }

    .frame-1948756809 {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .frame-1948756808 h2 {
        font-size: 32px;
        line-height: 120%;
    }

    .frame-1948756808 p {
        font-size: 20px;
        line-height: 150%;
    }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .banners {
        padding: 24px 16px;
    }

    .frame-1948756809 {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .button-and-vector {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }

    .profile-octagon {
        display: block; /* Отображаем вектор справа от кнопки */
    }

    .profile-octagon svg {
        height: auto;
    }
}