.about {
    color: #1D1D1D;
    font-size: 16px;
    line-height: 1.6
}

.about h1,
.about h2,
.about h3 {
    text-align: center;
}

@media only screen and (max-width: 779px) {
    .about h1,
    .about h2,
    .about h3 {
        font-size: 2.2rem !important;
        line-height: 1.2 !important;
    }
}

.about .profile__block {
    background: #fff;
}

.about .about__header {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 4rem 0 1.5rem;
}

.about .about__description {
    padding: 4rem 0 4.4rem;
    font-size: 2.4rem;
    line-height: 1.5;
    text-indent: 47%;
    background: #fff;
    /*position: sticky;*/
    /*z-index: 1;*/
    /*top: 14rem;*/
    margin-top: -4rem;
}

@media only screen and (max-width: 779px) {
    .about .about__description {
        padding: 6rem 0 4.4rem;
        text-indent: 0;
    }
}

.about .about__banner {
    height: 320px;
    overflow: hidden;
}

@media only screen and (max-width: 779px) {
    .about .about__banner {
        height: 244px;
    }
}

.about .about__banner img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.about .about__header .about__list li {
    width: calc((100% - 16rem) / 3)
}

@media only screen and (max-width: 1023px) {
    .about .about__header .about__list li {
        width: calc((100% - 8rem) / 3)
    }
}

.about .about__list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 8rem;
    padding: 2.6rem 0;
}

@media only screen and (max-width: 1023px) {
    .about .about__list {
        gap: 4rem;
    }
}

@media only screen and (max-width: 779px) {
    .about .about__list {
        flex-direction: column;
    }
}

.about .about__list li {
    position: relative;
}
@media only screen and (max-width: 779px) {
    .about .about__list li {
        width: 100% !important;
    }
}


.about .about__list li:not(:last-child):after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: -4rem;
    width: 1px;
    background: #EAEEF4;
}

@media only screen and (max-width: 1023px) {
    .about .about__list li:not(:last-child):after {
        right: -2rem;
    }
}

@media only screen and (max-width: 779px) {
    .about .about__list li:not(:last-child):after {
        right: 0;
        left: 0;
        width: 100%;
        height: 1px;
        top: auto;
        bottom: -2rem;
    }
}

.about .about__list li .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background: #C4997A;
    color: #fff;
    font-size: 2.8rem;
    line-height: 4rem;
    margin: 0 auto 1.5rem;
}

.about .about__list li h2,
.about .about__list li h3 {
    text-align: center;
    margin-bottom: 1rem;
}

.about .about__list li .map {
    margin-top: 1.5rem;
    width: 100%;
    height: 160px;
    filter: grayscale(100);
}

.about .about__mission {
    padding: 5.3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.2rem;
    text-align: center;
}

.about .about__mission h3 {
    max-width: 570px;
}

.about .about__content {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 2rem 0;
}

.about .about__vantages {
    display: flex;
    flex-direction: column;
}

.about .about__vantages h2 {
    text-align: center;
}

.about .about__vantages .about__list li {
    width: calc((100% - 24rem) / 4)
}

@media only screen and (max-width: 1023px) {
    .about .about__vantages .about__list li {
        width: calc((100% - 12rem) / 4)
    }
}

.about .about__compaign {
    background: #F0F5FC;
    padding: 3.5rem 0;
}

.about .about__compaign .container .button-container {
      display: flex; /* Используем Flexbox для горизонтального расположения */
      gap: 10px; /* Расстояние между кнопками */
      margin-top: 20px;
}

.about .about__compaign .text {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.about .about__compaign .text p {
    max-width: 850px;
    text-align: center;
    font-size: 22px;
    line-height: 1.35;
}

.about .about__maps {
    padding: 5rem 0;
}

.about .about__maps h2 {
    text-align: center;
    margin-bottom: 2.5rem
}

.about .about__maps .about__list li {
    text-align: center;
    width: calc((100% - 8rem)/2)
}

@media only screen and (max-width: 1023px) {
    .about .about__maps .about__list li {
        width: calc((100% - 4rem)/2)
    }
}

.about .about__offices {
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
    text-align: center;
}