.checkbox-container {
    display: flex;
    align-items: center;
    gap: 15px; /* Расстояние между чекбоксом и текстом */
}
.confirmation-text {
    font-size: 14px;
    color: #333; /* Цвет текста */
}

.confirmation-text a {
    color: #007bff; /* Цвет ссылок */
    text-decoration: none; /* Убираем подчеркивание */
}

.confirmation-text a:hover {
    text-decoration: underline; /* Подчеркивание при наведении */
}
.forgot-password-link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px; /* Опционально: добавляет отступ снизу */
}
.sign-up-link {
    display: flex;
    justify-content: space-between; /* Распределяет пространство между элементами */
    align-items: center; /* Выравнивает элементы по вертикали по центру */
    width: 100%; /* Убедитесь, что контейнер занимает всю доступную ширину */
    margin-top: 15px; /* Отступ сверху, опционально */
}
@media (max-width: 768px) {
    .main-form__img {
        display: none;
    }

    /* Дополнительно, если необходимо изменить стили других элементов при скрытии изображения */
    .main-form-container {
        flex-direction: column;
    }

    .main-form__right {
        width: 100%;
    }
}