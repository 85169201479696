.modal.modal-share .modal__content {
    background: #c9d3dc;
    max-width: 505px;
}

.modal.modal-share .modal__form {
    border-width: 4px;
}

.modal.modal-share .button.close {
    border: 2px solid transparent;
}

.modal.modal-share .button.close:hover {
    background: transparent;
    border: 2px solid #fff;
}

.popup .modal .content {
    background: transparent;
    overflow: visible;
}
